import * as React from "react";
import './KeyFeatures.scss'

export default class KeyFeatures extends React.Component{
    public render() {
        return (
            <div className="key-features">
                <div className="key-features__container">
                <h1 className="key-features__title">Schlüsselfunktionen von SAP SRM </h1>
                    <ul className="key-features__list">
                        <li className="marked-list__item">Zentrale Beschaffungsplanung und -verwaltung</li>
                        <li className="marked-list__item">Automatisierung der Supply Chain mit Lieferanten</li>
                        <li className="marked-list__item">Markt- und Lieferantenanalyse</li>
                        <li className="marked-list__item">Einheitliches Dokumentationsmanagement sowie Procurement-Reportingsystem</li>
                    </ul>
                    <div className="key-features__right">
                        <h1 className="key-features__sub-title">
                            Funktionen von SAP SRM können je nach Anforderungen sowie Budgetierung folgendermaßen implementiert werden:
                        </h1>
                        <ul className="key-features__icon-list">
                            <li className="key-features__icon-list__item key-features__icon-list__item--autonom">als eigenständiges Modul oder in Integration mit anderen Komponenten der SAP-Plattform</li>
                            <li className="key-features__icon-list__item key-features__icon-list__item--local">in lokalen, Cloud- oder Hybrid-Systemen</li>
                    </ul>


                    </div>
                    <p className="key-features__text">
                        Konsolidieren Sie SAP SRM und andere SAP ERP-Lösungen, 
                        um Prozesse des gesamten Beschaffungszyklus zu optimieren, 
                        d.h. von der Bedarfsermittlung und Ressourcenplanung bis zur 
                        Erfassung des Eingangs von Waren und Dienstleistungen.
                    </p>
                </div>
            </div>
        );
    }
}