import * as React from "react";
import './WhatPartnersGet.scss'

export default class WhatPartnersGet extends React.Component{
    public render() {
        return (
            <section className="what-partners-get section">
                <div className="what-partners-get__container container">
                    <div className="what-partners-get__text">
                        <p>Im digitalen Zeitalter entwickelt sich die IT-Branche rasant: 
                            neue Ideen und Tools erweitern das Spektrum der Anwendungsmöglichkeiten.
                             Folglich wird die Verknüpfung neuer Technologien 
                             mit bestehenden Strukturen immer komplexer.</p>

                        <p>Die auf dem Markt vorhandene Software kann oft nicht die 
                            Probleme vieler Unternehmens gezielt und kosteneffizient lösen.</p>
                            
                        <p>In solchen Fällen ist die richtige Wahl eine individuelle Softwareentwicklung
                            oder Modernisierung der bestehenden Software, die den Anforderungen entspricht.</p>
                    </div>
                </div>
            </section>
        );
    }
}