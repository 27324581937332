import { IOneColumnTextProps } from "src/components/shared/containers/OneColumnText/OneColumnText"
import { IStatementProps } from "src/components/shared/sections/Statement/Statement"

const statementProps: IStatementProps = {
    page: 'career',
    title: 'Karriere',
    subtitle: 'enconso hält immer Ausschau nach Talenten und Professionals'
}

const oneColumnTextPropsList: IOneColumnTextProps[] = [
    {text: `Obwohl der IT-Arbeitsmarkt in Deutschland der hohen Nachfrage an Talenten und Fachkräften nicht hinterherkommen kann, tun sich viele Young Professionals und Absolventen sehr schwer, das richtige Unternehmen für den Einstieg in die IT-Welt zu finden. Dies ist nicht überraschend, da gerade jungen Entwicklern kein klares Bild der Entwicklungmöglichkeiten innerhalb eines Unternehmens gezeigt wird. Oft ist es leider der Fall, dass die Strukturen eines klassischen mittelständischen oder Großunternehmens auch in der IT-Abteilung stark hierarchisch geprägt sind und man sich in diesen schnell fest verfangen kann.`},
    {text: `enconso vermeidet genau dies als Arbeitgeber und folgt einem komplett dynamischen Arbeitskonzept. Die projektbezogene Arbeit in nahezu allen Programmiersprachen über verschiedene Industrien erlaubt eine sehr steile Lernkurve und bietet tiefen Einblick in die aktuellen Industrietrends an. Die Kunden, bei denen die Projekte durchgeführt werden, sind oft bekannte Großunternehmen, die signifikante Überarbeitungen ihrer IT-Systeme implementieren. `},
    {text: `enconso als Arbeitgeber bietet dir die Möglichkeit, von Beginn an große Verantwortung zu übernehmen und dich fachlich zu entfalten. Die Lernmöglichkeiten im Bereich Development sind bei uns enorm und bei weitem größer als bei einzelnen Unternehmen, bei denen man oft nur einen kleinen Teil des IT-Spektrums zu sehen bekommt.`},
    {text: `Wir suchen stets talentierte Informatikabsolventen mit idealerweise starken Kommunikationsfähigkeiten sowie erfahrene Developer, die ein größeres Bild sehen möchten. Sende deine aussagekräftigen Bewerbungsunterlagen an info@enconso.com und wir melden uns in jedem Fall bei dir zurück!`},
]

export const careerData = {
    statementProps,
    oneColumnTextPropsList
}