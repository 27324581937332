import { ISSmallInfoBlocksProps } from "src/components/pages-components/SapSolutions/SapLosungen/SmallInfoBlocks/SmallInfoBlocks";
import { IStatementProps } from "src/components/shared/sections/Statement/Statement";

const statementProps: IStatementProps = {
    title: `Services`,
    subtitle: `Unsere angebotenen Leistungen im Überblick`,
    page: "services",
}

const smallInfoBlockProps: ISSmallInfoBlocksProps = {
    blocks: [
        {
            title: `Beratung`,
            info: `Projektmanagement-bezogene Aufgaben wie Roadmap- und Zeitplanung, Analyse bestehender IT-Systeme sowie -Strukturen in Anbetracht des geplanten Projekts, Ressourcenplanungen und Zieldefinition.`,
            class: "Beratung",
            
        },
        {
            title: `Implementierung / Entwicklung`,
            info: `Technische Realisierung von Lösungen auf SAP- und / oder Non-SAP-Basis durch Neuprogrammierung. Die Entwicklung erfolgt auf Basis von vorher festgelegten Konzeptionen und der Programmarchitektur.`,
            class: "Implementierung",
            
        },
        {
            title: `Anpassung`,
            info: `Adaptation bereits vorhandener Lösungen, um unternehmensspezifische Bedürfnisse bestenfalls vollständig abzudecken. Standardisierte Produkte versuchen, die breite Masse anzusprechen und berücksichtigen nicht die Individualität jedes Unternehmens, die je nach Größe und Industrie stark variieren können.`,
            class: "Anpassung",
            
        },
        {
            title: `Modernisierung`,
            info: `Upgrade von Altsystemen zur Steigerung der Agilität und Effizienz, Verbesserung der Datenqualität sowie Senkung von langfristigen Kosten (z.B. für Wartung).`,
            class: "Modernisierung",
            
        },
        {
            title: `Support`,
            info: `IT-Experten zur Lösung von komplexen, codespezifischen (oft back-end) Problemen.`,
            class: "Support",
            
        },
        {
            title: `Offshore-Development`,
            info: `Bereitstellung von Remote-Developern als kostengünstige Alternative zu Onsite-Entwicklern, die auf Wunsch über einen Consultant koordiniert werden können. Besonders empfehlenswert für junge und budgetgebundene Unternehmen.`,
            class: "OffshoreDevelopment",
            
        }
    ]
};

export const ServicesData = {
    statementProps,
    smallInfoBlockProps
}