import * as React from "react";
import Title from "../../containers/Title/Title";
import './ListWithСircles.scss';

export interface IListWithСirclesProps {
    title: string,
    theme: 'blue' | 'white';
    list: Array<{
        srcImg: string;
        text: string;
    }>;
}


export default class ListWithCircles extends React.Component<IListWithСirclesProps> {

    public render() {
        const title = this.props.title;
        const theme = `list-with-сircles--${this.props.theme}`;
        const list = this.props.list.map((item, i) => 
            (<li className="list-with-сircles__item" key={i}>
                <div className="list-with-сircles__item-img" style={{ 
                    backgroundImage: `url('${item.srcImg}')`}} />
                <p className="list-with-сircles__item-text">{item.text}</p>
            </li>));

            return (
                <section className={`list-with-сircles ${theme} section`}>
                    <Title type='main' theme={this.props.theme} text={title} />
                    <div className="list-with-сircles__wrap container">
                        <ul className="list-with-сircles__list">
                            {list}
                        </ul>
                    </div>
                </section>
            );
    }
}