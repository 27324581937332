import * as React from "react";
import './FormContacts.scss';
import MessageSent from "../../sections/MessageSent/MessageSent";


interface IFormContactsState {
    isOpened: boolean,
    isChecked: boolean,
    hasSent: boolean;
}


export default class FormContacts extends React.Component<{}, IFormContactsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isOpened: false,
            isChecked: false,
            hasSent: false
        } 
        this.onClick = this.onClick.bind(this);
        this.onClickCheckBox = this.onClickCheckBox.bind(this);
        this.onClickSendMessage = this.onClickSendMessage.bind(this);
        this.onClickCloseMessage = this.onClickCloseMessage.bind(this);
    }
    public onClick() {
        this.setState({ isOpened: !this.state.isOpened}); 
    }
    public render() {
        const disabled = !this.state.isChecked ? 'button--disabled' : '';
        const active = this.state.isOpened ? 'active' : '';
        return (
            <div className={`form-contacts ${active}`}>
                <button className="button button--contact button--position-fixed" onClick={this.onClick} />
                <div className={`form-contacts__wrap`}>
                    <h3 className="form-contacts__title">Kontakt</h3>
                    <p className="form-contacts__text">Noch Fragen? Bitte hinterlassen Sie hier Ihre Kontaktdaten und wir setzen uns mit Ihnen in Verbindung.</p>
                    <p className="form-contacts__phone">+49 (0) 6172 868449</p>
                    {/*<p className="form-contacts__message">Schreiben Sie uns</p>*/}
                    <input className="form-contacts__input" id="fc-name" type="text" placeholder="Ihre Name" />
                    <input className="form-contacts__input" id="fc-phone" type="text" placeholder="Ihre Telefonnummer" />
                    <input className="form-contacts__input" id="fc-email" type="email" placeholder="Ihre E-Mail-Adresse" />
                    <div className="form-contacts__checkbox">
                            <div  className="form-contacts__box">
                                <input type="checkbox" id="form-contacts__checkbox" onClick={this.onClickCheckBox}  />
                                <label  htmlFor="form-contacts__checkbox" />
                            </div>
                            <div className="form-contacts__label">
                                <p className="form-contacts__checkbox-text">
                                    Ich stimme der Verarbeitung meiner
                                </p>
                                <a className="form-contacts__link" href="/datenschutz" >
                                    persönlichen Daten zu 
                                </a>                                    
                            </div>
                        </div> 
                    <button className={`form-contacts__button button ${disabled}`}onClick={this.onClickSendMessage}>Absenden</button>
                    <button className="form-contacts__button-exit" onClick={this.onClick}/>
                </div>
                <MessageSent hasSent={this.state.hasSent} onClose={this.onClickCloseMessage}  />
            </div>
        );
    }
    private onClickCloseMessage () {
        this.setState({hasSent: false});
    }

    private onClickCheckBox() {
        this.setState({
            isChecked: !this.state.isChecked
        });
    }

    private onClickSendMessage(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const name = (document.getElementById('fc-name') as HTMLInputElement);
        const email = (document.getElementById('fc-email') as HTMLInputElement);
        const phone = (document.getElementById('fc-phone') as HTMLTextAreaElement);

        if (this.state.isChecked && (name.value !== '' && email.value !== '' && phone.value !== '')) {
            const message = {
                name: name.value,
                email: email.value,
                phone: phone.value
            }
            const data = {
                message: message,
                subject: 'Rufen Sie zurück | Back call'
            }

            
            fetch('https://enconso-api.vistar.su/messages/call', { method: 'POST', headers:{'Content-Type':'application/json'}, body: JSON.stringify(data) });

            name.value = '';
            email.value = '';
            phone.value = '';

            this.setState({hasSent: true});

            this.onClickCheckBox();
            (document.getElementById('form-contacts__checkbox') as HTMLInputElement).checked = false;
        }
    }
}