import * as React from 'react';
import './OneColumnText.scss';

export interface IOneColumnTextProps {
    title?: string;
    text: string;
}

export default class OneColumnText extends React.Component<IOneColumnTextProps> {
  public render() {
    return (
      <div className='one-column-text'>
        <h3 className='one-column-text__title'>{this.props.title}</h3>
        <p>{this.props.text}<br /></p>
      </div>
    );
  }
}
