import * as React from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AboutUs from 'src/components/pages/AboutUs/AboutUs';
import Career from 'src/components/pages/Career/Career';
import CaseStudies from 'src/components/pages/CaseStudies/CaseStudies';
import CustomDevelopment from 'src/components/pages/CustomDevelopment/CustomDevelopment';
import DataProtection from 'src/components/pages/DataProtection/DataProtection';
import Imprint from 'src/components/pages/Imprint/Imprint';
import Main from 'src/components/pages/Main/Main';
import SapAriba from 'src/components/pages/SapSolutions/SapAriba/SapAriba';
import SapBw4Hana from 'src/components/pages/SapSolutions/SapBw4Hana/SapBw4Hana';
import SAPLosungen from 'src/components/pages/SapSolutions/SAPLosungen/SAPLosungen';
import SapS4Hana from 'src/components/pages/SapSolutions/SapS4Hana/SapS4Hana';
import SapSRM from 'src/components/pages/SapSolutions/SapSRM/SapSRM';
import Services from 'src/components/pages/Services/Services';

export default class Root extends React.Component {
    public render() {        
        return (
            <Router>
                <Switch>
                    <Route exact={true} path="/" component={Main} />
                    <Route exact={true} path="/non-sap-solutions" component={CustomDevelopment} />

                    <Route exact={true} path="/sap-solutions" component={SAPLosungen} />
                    <Route exact={true} path="/sap-solutions/sap-s4hana" component={SapS4Hana} />
                    <Route exact={true} path="/sap-solutions/sap-srm" component={SapSRM} />
                    <Route exact={true} path="/sap-solutions/sap-ariba" component={SapAriba} />
                    <Route exact={true} path="/sap-solutions/sap-bw-4hana" component={SapBw4Hana} />

                    <Route exact={true} path="/case-studies" component={CaseStudies} />
                    <Route exact={true} path="/karriere" component={Career} />
                    <Route exact={true} path="/services" component={Services} />

                    <Route exact={true} path="/impressum" component={Imprint} />
                    <Route exact={true} path="/datenschutz" component={DataProtection} />
                    <Route exact={true} path="/uber-uns" component={AboutUs} />

                    <Route path="*" component={Main} />
                </Switch>
            </Router>
        );
    }
}