import * as React from 'react';
import './Installing.scss';

export interface IInstallingSapS4HanaProps {
}

export interface IInstallingSapS4HanaState {
}
const installationPicturesURL = [
    "/img/installation-options/1.jpg",
    "/img/installation-options/2.jpg",
    "/img/installation-options/3.jpg"
];
export default class InstallingSapS4Hana extends React.Component<IInstallingSapS4HanaProps, IInstallingSapS4HanaState> {


    constructor(props: IInstallingSapS4HanaProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        return (
            <section className="installing-sap-s4hana section">
                <div className="installing-sap-s4hana__wrap container">
                    <h2 className="installing-sap-s4hana__title">SAP S/4HANA Implementierung</h2>
                    <h3 className="installing-sap-s4hana__subtitle">Implementierungsoptionen:</h3>
                    {this.renderInstallationVariants()}
                    <h3 className="installing-sap-s4hana__subtitle">Möglichkeiten der Umsetzung*:</h3>
                    <ul className="installing-sap-s4hana__implementation-methods">
                        <li>
                            <h4>Neuimplementierung </h4>
                            <p>Für Unternehmen, die Non-SAP-Produkte oder ältere SAP-ERP-Systeme verwenden.</p>
                        </li>
                        <li>
                            <h4>Transformation der Architektur</h4>
                            <p>Zusammenführung bestehender SAP-Systeme zu einem einzigen
                                 SAP S / 4HANA-System oder Trennung in mehrere Einzelkomponenten.</p>
                        </li>
                    </ul>
                    <span className="installing-sap-s4hana__subtext">* Unabhängig von der Implementierungsoption 
                        übertragen wir alle Daten des Vorgängersystems verlustfrei</span>
                    <h3 className="installing-sap-s4hana__subtitle">Über Enconso</h3>
                    <div className="installing-sap-s4hana__experience">
                        <p>Enconso ist ein erfahrener Partner
                            in der Implementierung und Migration von 
                            SAP S/4 HANA und bietet eine Vielzahl von eigenentwickelten Ansätzen für verschiedene
                            Industrien (z.B. Metallurgie, Chemie, Bergbau, Automotive, Einzelhandel) an.</p><br />
                        <p>Unsere Systeme und Analysentools beseitigen automatisch Bugs im Quellcode
                            & erkennen nicht-verwendete Funktionen und bieten die Option an, diese im 
                            Zielsystem nicht zu integrieren. Darüberhinaus bieten wir ein spezielles
                            Tool an, das eine parallele Ausführung von mehreren technischen 
                            Operationen sowie Migrationen erlaubt.</p>
                    </div>
                </div>
            </section>
        );
    }
    public renderInstallationVariants(): JSX.Element {
        return (
            <ul className="installing-sap-s4hana__installation-options">
                <li className="installing-sap-s4hana__installation-options__item" style={{ backgroundImage: `url(${installationPicturesURL[0]})` }}>
                        <div>Lokal</div>
                    </li>
                    <li className="installing-sap-s4hana__installation-options__item" style={{ backgroundImage: `url(${installationPicturesURL[1]})` }}>
                    <div>Cloud</div>
                    </li>
                    <li className="installing-sap-s4hana__installation-options__item"  style={{ backgroundImage: `url(${installationPicturesURL[2]})` }}>
                    <div>Hybrid</div>
                    </li>
                </ul>
            );
    }
}
