import * as React from 'react';
import './TwoColumnText.scss';
import '../MarkedList/MarkedList.scss';


export interface ITwoColumnTextProps {
    title?: string;
    type?: string
    leftBlock: string[];
    rightBlock: string[];
}

export default class TwoColumnText extends React.Component<ITwoColumnTextProps> {
    public render() {
        return (
            <section className="two-column-text__information">
                <h3 className="two-column-text__information-title">{this.props.title}</h3>

                <div className="two-column-text__blocks_container">
                    <div className="two-column-text__block">{this.renderBlock(this.props.leftBlock)}</div>
                    <div className="two-column-text__block">{this.renderBlock(this.props.rightBlock)}</div>
                </div>
            </section>
        );
    }
    private renderBlock(block: string[]) {
        return block.map((line,i) => this.props.type==='marked' ? <li className="marked-list__item" key={i}>{line}</li> : <p key={i}>{line}</p>)
    }
}
