import * as React from 'react';
import "./Title.scss";

export interface ITitleProps {
  theme?: 'blue' | 'white';
  type: 'main' | 'sub';
  id?: string;
  text: string;
}

export default class Title extends React.Component<ITitleProps> {
  public render() {
    const type = this.props.type ? this.props.type : '';
    const theme = this.props.theme ? this.props.theme : '';
    const id = this.props.id ? this.props.id : '';
    
    return (
      <div className={`container-title ${type} ${theme}`} id={`${id}`}>
        {this.props.text}
      </div>
    );
  }
}
