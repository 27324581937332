import * as React from 'react';
export interface IContentContainerProps {
  id?: string
}

export default class ContentContainer extends React.Component<IContentContainerProps> {
  public render() {
    return (
      <section className={this.props.id !== '' ? 'section section--' +  this.props.id : 'section'}>
        <div className="container">
          {this.props.children}
        </div>
      </section>
    );
  }
}
