import "./IntelligentITSolutions.scss";

import * as React from 'react';
import MarkedList, { IMarkedListProps } from "src/components/shared/containers/MarkedList/MarkedList";

const intelligentITSolutionsData =  {
    title:`Intelligente IT-Lösungen für Ihr Unternehmen`,
    list: [
        `schnell`,
        `zuverlässig`,
        `effizient`
    ],
    text:`Im digitalen Zeitalter entwickelt sich die IT-Branche rasant: neue Ideen und 
        Tools erweitern das Spektrum der Anwendungsmöglichkeiten. 
        Folglich wird die Verknüpfung neuer Technologien mit bestehenden Strukturen immer komplexer.`,
};

const checkListData: IMarkedListProps[] = [
    {
        title: ``,
        list: [
            `Die auf dem Markt vorhandene Software kann oft nicht die Probleme vieler Unternehmens gezielt und kosteneffizient lösen.`,
            `In solchen Fällen ist die richtige Wahl eine individuelle Softwareentwicklung oder Modernisierung der bestehenden Software, die den Anforderungen entspricht.` ,
            `Wir bieten kundenspezifische Web- und mobile Anwendunsentwicklung für verschiedene Geschäftsbereiche an, um die bestmögliche Effizienz zu gewähren.`,
        ]
    },
    {
        title: `Vorteile der digitalen Transformations Ihres Unternehmens`,
        list: [
            `Maximale Datenkontrolle und klare Berichtserstattung`,
            `Erleichterte Entscheidungsfindung auf vollständige Datenbasis`,
            `Prozessautomatisierung und Effizienzerhöhung`,
            `Optimierungsmöglichkeiten der Umsätze sowie Kosten`,
        ],
    }
]



export default class IntelligentITSolutions extends React.Component {
  public render() {
      
    return (
        <section className="intelligent-it-solution section">
            <div className="intelligent-it-solutions__container container">
                <h1 className="intelligent-it-solutions__title main-title main-title--white">{intelligentITSolutionsData.title}</h1>
                <ul className="intelligent-it-solutions__advantages">
                    {intelligentITSolutionsData.list.map((item, i) => (
                        <li key={i} className="intelligent-it-solutions__advantages-item">{item}</li>
                    ))}
                </ul>
                <p className="intelligent-it-solutions__text">{intelligentITSolutionsData.text}</p>
                {checkListData.map((item, i) => ( <MarkedList key={i} {...item} /> ))}
            </div>
        </section>
    );
  }
}
