import * as React from "react";
import './MessageSent.scss';

interface IMessageSentProps {
    hasSent: boolean;
    onClose: () => void;
}
export default class MessageSent extends React.Component<IMessageSentProps> {
    constructor(props: IMessageSentProps) {
        super(props);
        console.log(this.props);
    }
    render() {
        return (
            <section className={`message-sent ${this.props.hasSent ? 'opened' : ''}`}>
                <div className="message-sent__modal">
                    <button className="message-sent__button-exit" onClick={this.props.onClose} />
                    <p className="message-sent__info">Ihre Nachricht wurde erfolgreich gesendet</p>
                    <button className={`message-sent__button button`} onClick={this.props.onClose}>Schließen</button>
                </div>
            </section>
        );
    }
}