

const compAndBFData = [
    {   
        title: 'FI - Finanzbuchhaltung',
        itemTitleList: [
                `Bilanzierung der Finanzgeschäfte des Unternehmens, auch mit Kunden und Lieferanten;`,
                `Bearbeitung von Forderungen und Verbindlichkeiten;`,
                `Cash Management;`,
                `Bankzahlungen und Abstimmungen.`
        ],
        srcImg: "/img/accordion/fi.svg"
    },
    {   
        title: 'CO – Controlling (Rechnungswesen und Finanzplanung)',
        itemTitleList: [
                `Verwaltung von Kosten- und Gewinnpunkten, Innenaufträgen;`,
                `Finanzielle Planung;`,
                `Ermittlung der Produktkosten durch Vergleich der prognostizierten und tatsächlichen Kosten.`,
        ],
        srcImg: "/img/accordion/co.svg"
    },
    {   
        title: 'SD – Vertrieb',
        itemTitleList: [
                `Verkauf von Produkten / Dienstleistungen auf einem nationalen oder internationalen Markt;`,
                `Verteilung;`,
                `Rechnungsstellung, Kreditausstellung, Kundenretoure.`,
        ],
        srcImg: "/img/accordion/sd.svg"
    },
    {   
        title: 'MM – Materialverwaltung',
        itemTitleList: [
                `Beschaffung von Materialien und Dienstleistungen;`,
                `Bestandsmanagement.`,
        ],
        srcImg: "/img/accordion/mm.svg"
    },
    {   
        title: 'PP – Produktionsplanung',
        itemTitleList: [
                `Organisation und Optimierung von Lieferketten.`,
        ],
        srcImg: "/img/accordion/pp.svg"
    },
    {   
        title: 'QM – Qualitätsmanagement',
        itemTitleList: [
                `Integration in die Prozesse der Beschaffung, Produktion, Verkauf, Wartung der Ausrüstung;`,
                `Audit-Management.`,
        ],
        srcImg: "/img/accordion/qm.svg"
    },
    {   
        title: 'PM – Wartung / Produktionskontrolle',
        itemTitleList: [],
        srcImg: "/img/accordion/pm.svg"
    },
    {   
        title: 'CS – Kundendienst',
        itemTitleList: [
                `Kundenservice`,
        ],
        srcImg: "/img/accordion/cs.svg"
    },
    {   
        title: 'PS – Management großer und komplexer Projekte',
        itemTitleList: [],
        srcImg: "/img/accordion/ps.svg"
    },
    {   
        title: 'HCM – Human Capital Management',
        itemTitleList: [
                `Berechnung des RFP;`,
                `Zeiterfassung;`,
                `Urlaub;`,
                `Karriereentwicklung;`,
                `Sicherheit am Arbeitsplatz.`,
        ],
        srcImg: "/img/accordion/hcm.svg"
    }
]

export default compAndBFData;