import * as React from 'react';
import "./CustomAccordion.scss";

export interface ICustomAccordionProps {

    title: string;
    itemTitleList: string[];
    srcImg: string;

    index: number;
    selected: boolean;
    selectList: (index: number) => void;
}


export interface ICustomAccordionState {
    isActiveList: boolean;
    activeListItemIndex: number;
}

export default class CustomAccordion extends React.PureComponent<ICustomAccordionProps, ICustomAccordionState> {
    constructor(props: ICustomAccordionProps) {
        super(props);

        this.state = {
            isActiveList: this.props.selected,
            activeListItemIndex: this.props.index,
        };

        this.onClickAccordion = this.onClickAccordion.bind(this);
    }

    public render(): JSX.Element {
        
        const title = this.props.title; 
        const srcImg = this.props.srcImg;

        return (
            <div className={'custom-accordion ' + (this.props.selected ? ' active' : '' )}>
                    <div className="custom-accordion__block">
                        <h3 className="custom-accordion__title" onClick={this.onClickAccordion}>{title}<img alt="" src={srcImg} /></h3>
                        {this.renderList()}
                    </div>
                </div>);
        
    }

    private renderList(): JSX.Element {
        const list = this.props.itemTitleList;
        const activeListItemIndex = this.state.activeListItemIndex;

        return (<ul className={`custom-accordion__list`}>
                    {list.map((item, i) => 
                        (<ul key={i}>
                        <li className={`custom-accordion__item ${activeListItemIndex === i ? 'active' : ''}`} data-index={i}>
                            {item}
                        </li>
                        </ul>))}
                </ul>)
    }

    private onClickAccordion(e: React.MouseEvent<HTMLElement>) {
        if (this.props.itemTitleList.length > 0) {
            this.props.selectList(this.props.index);
        }
    }
}
