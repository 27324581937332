import * as React from 'react';
import FormContacts from 'src/components/shared/components/FormContacts/FormContacts';
import Feedback from 'src/components/shared/sections/Feedback/Feedback';
import Footer from 'src/components/shared/sections/Footer/Footer';
import Statement from 'src/components/shared/sections/Statement/Statement';
import { imprintData } from './data';
import ContentContainer from 'src/components/shared/containers/ContentContainer/ContentContainer';
import OneColumnText from 'src/components/shared/containers/OneColumnText/OneColumnText';

export interface IImprintProps {
}

export default class Imprint extends React.Component<IImprintProps> {
  public render() {
    return (
      <div>
        <Statement {...imprintData.statementProps} />
        <ContentContainer id="">
            {imprintData.oneColumnTextPropsList.map((item,i) => (<OneColumnText key={i} {...item} />))}
          </ContentContainer>
        <FormContacts />
        <Feedback theme="blue" />
        <Footer />
      </div>
    );
  }
}
