import * as React from 'react';
import ArrowTo from '../../components/ArrowTo/ArrowTo';
import Header from '../Header/Header';
import './Statement.scss';
import { navItems, INavItems } from "./data";
import { HashLink as Link } from "react-router-hash-link";

export interface IStatementLinkProps {
    title: string;
    link: string;
}
export interface IStatementProps {
    title?: string;
    subtitle?: string;
    page: 'about-us' | 'career' | 'case-studies' | 'custom-development' | 'data-protection' | 'imprint' |
    'main' | 'sap-ariba' | 'sap-bw' | 'sap-losungen' | 'sap-s4hana' | 'sap-srm' | 'services';

    microtitle?: string;
    text?: string;
}

export default class Statement extends React.Component<IStatementProps> {

    public render() {
        /* 
            statement на страницах main и imprint имеет несколько отличный от других вид, а потому 
            подключаются соответствующие стили:  statement--main или statement--imprint
         */
        const className = 
                `statement${
                    this.props.page === 'main' ? ' statement--main' : 
                    this.props.page === 'imprint' ? ' statement--imprint' : ''}`;  

        return (
            <div className= {className}style={{ backgroundImage: `url('/img/statement/${this.props.page}.jpg')` }}>
                <Header />
                {
                    className === 'statement statement--main' && window.screen.width > 768 ? this.renderInfographicsContent() : this.renderBasicContent()
                }
                <ArrowTo />
                <div id='startAnchor' />
            </div>
        );
    }

    private renderBasicContent() {
        return (
                <div className="statement__content">
                    <h3 className="statement__title">{this.props.title}</h3>
                    <p className="statement__subtitle">{this.props.subtitle}</p>
                    <p className="statement__microtitle">{this.props.microtitle}</p>
                    <p className="statement__text">{this.props.text}</p>
                </div>
        );
    }

    private renderInfographicsContent() {
        const nav = navItems;
        return (
            <div className="statement__content">
            {
                nav.map((item: INavItems, i: number): JSX.Element => {
                    return (
                        <div className={`statement__content__link statement__content__link--` + i}><Link to={item.link} className="nav__item" key={i}>{item.title}</Link></div>                            );
                })
            }
            </div>
        );
    }
}