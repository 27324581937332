import * as React from "react";
import './KeyEffect.scss'

export default class KeyEffect extends React.Component{
    public render() {
        return (
            <div className="section">
                <div className="container">
                    <h1 className="key-effect__title">SAP Ariba Vorteile</h1>
                    <ul className="key-effect__list">
                        <li className="marked-list__item">Verkürzung des Beschaffungszyklus</li>
                        <li className="marked-list__item">
                            Beschleunigung von Arbeitsabläufen und Transkationen
                        </li>
                        <li className="marked-list__item">Reduzierung der Beschaffungskosten</li>
                        <li className="marked-list__item">
                            Business Intelligence (BI) und Transaktionsmanagement in Echtzei
                        </li>
                        <li className="marked-list__item">
                            Qualifizierte Lieferantenauswahl und signifikante Risikominderung in der Supply Chai
                        </li>
                        <li className="marked-list__item">
                            Allgemeine Effizienzsteigerung von Beschaffungsprozessen
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}