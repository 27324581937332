import { ICardListProps } from "src/components/shared/containers/CardList/CardList";
import { ITwoColumnTextProps } from "src/components/shared/containers/TwoColumnText/TwoColumnText";
import { IStatementProps } from "src/components/shared/sections/Statement/Statement";

const statementProps : IStatementProps =  {
    title:`SAP S/4HANA`,
    subtitle: `Intelligente Lösung für die Geschäftsentwicklung`,
    page: "sap-s4hana"
}

const twoColumnTextProps: ITwoColumnTextProps = {
    title: 'SAP S/4HANA',
    type: 'marked',
    // TODO
    leftBlock: [
    'Next-Gen ERP-System in der Software-Entwicklung für Unternehmen. Intelligentes Management für Unternehmen jeder Größenordnung in jedem Geschäftsbereich.',
    'Einheitliches System zur Verwaltung von Unternehmensressourcen, Supply Chain, Beziehungen zu Kunden und Lieferanten auf einer Plattform.',
    ],
    rightBlock: [
    'Umfangreiche und maximale Deep Analysis in Echtzeit möglich.',
    'Beeindruckende Berichtsfunktionen mit einer Vielzahl von vorgefertigten Berichten und Tools.'
    ]
}

const cardListProps: ICardListProps = {
    title: `SAP-Plattform für Ihr Unternehmen: Die Vorteile`,
    theme: `blue`,
    listTheme: ``,
    list: [
        {
            title: `Einheitliches Informationssystem`,
            text: `für alle Prozesse und Abteilungen des Unternehmens`,
            srcImg: `/img/card-list/sap-s4hana/1.svg`
        },
        {
            title: `Modularer Systemaufbau`,
            text: `Prozessautomatisierung durch sukzessiver Ergänzung neuer Komponenten`,
            srcImg: `/img/card-list/sap-s4hana/2.svg`
        },
        {
            title: `Datenrelevanz`,
            text: `Transaktionsverarbeitung, Datenanalyse, Echtzeitprognose (für neue Produkte auf der HANA-Plattform)`,
            srcImg: `/img/card-list/sap-s4hana/3.svg`
        },
        {
            title: `Umfangreiche Analytics-Möglichkeiten`,
            text: `durch Verwendung künstlicher Intelligenz (AI) sowie virtueller Datenmodelle`,
            srcImg: `/img/card-list/sap-s4hana/4.svg`
        },
        {
            title: `Möglichkeiten der Implementierungen`,
            text: `basierend auf Branche sowie Unternehmensgröße`,
            srcImg: `/img/card-list/sap-s4hana/5.svg`
        },
    ]
}

export const SapS4HanaData = {
    statementProps,
    twoColumnTextProps,
    cardListProps
}