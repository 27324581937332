import * as React from 'react';
import MarkedList, { IMarkedListProps } from '../MarkedList/MarkedList';
import OneColumnText, { IOneColumnTextProps } from '../OneColumnText/OneColumnText';
import Title, { ITitleProps } from '../Title/Title';
import TwoColumnText, { ITwoColumnTextProps } from '../TwoColumnText/TwoColumnText';

interface IElement {
    elementProps: IOneColumnTextProps | ITwoColumnTextProps | IMarkedListProps | ITitleProps;
    type: 'OneColumnText' | 'TwoColumnText' | 'MarkedList' | 'MainTitle';
    id?: string;
}
export interface IElementProps {
    elements: IElement[]
}

export default class Elements extends React.Component<IElementProps> {
  public render() {
    return (
        <div>
        {this.props.elements.map((element, i) => {
          switch (element.type) {
            case 'OneColumnText': {
              return <OneColumnText key={i} {...element.elementProps as IOneColumnTextProps} />
            }
            case 'TwoColumnText': {
              return <TwoColumnText key={i} {...element.elementProps as ITwoColumnTextProps} />
            }
            case 'MarkedList': {
              return <MarkedList key={i} {...element.elementProps as IMarkedListProps} />
            }
            case 'MainTitle': {
              return <Title key={i} {...element.elementProps as ITitleProps} />
            }
            default : {
              console.log('Error');
              return ''
            }
          }
        })}
        </div>
        )
  }
}
