import * as React from "react";
import KeyFeatures from "src/components/pages-components/SapSolutions/SapSRM/KeyFeatures/KeyFeatures";
import ServicesSRM from "src/components/pages-components/SapSolutions/SapSRM/ServicesSRM/ServicesSRM";
import FormContacts from "src/components/shared/components/FormContacts/FormContacts";
import CardList from "src/components/shared/containers/CardList/CardList";
import Feedback from "src/components/shared/sections/Feedback/Feedback";
import Footer from "src/components/shared/sections/Footer/Footer";
import Statement from "src/components/shared/sections/Statement/Statement";
import { SapSRMData } from "./data";


export default class SapSRM extends React.Component {
    public render() {
        return (
            <>
                <Statement {...SapSRMData.statementProps}/>
                <KeyFeatures />
                <CardList {...SapSRMData.cardListProps} />
                <ServicesSRM />
                <FormContacts />
                <Feedback theme="blue" />
                <Footer />  
            </>
        );
    }
}