import * as React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { INavItems, navItems, navServiceItems } from "./data";
import './Header.scss';
import '../Feedback/Feedback.scss';

export default class Header extends React.Component<{}, { isDisplayedMobileNav: boolean }> {

    constructor(props: any) {
        super(props);
        this.state = { isDisplayedMobileNav: false };
        this.onClickHamburger = this.onClickHamburger.bind(this);
    }

    public render() {
        return (
            <header id="topAnchor" className="header">
                {this.renderHeaderRow()}
                {this.state.isDisplayedMobileNav ? this.renderMobileNav() : ``}
                {window.screen.width > 768 ? this.renderMainMenu() : ``}
            </header>
        );
    }

    private renderSubNav(navItem: INavItems, key: number) {
        const theme = `nav__item--${navItem.theme}`;

        return (
            <div className={`nav__item ${theme} ${this.isActive(navItem.link)}`} key={key}>
                <Link to={navItem.link}>{navItem.title}</Link>
                <ul>
                    {
                        navItem.subNavItems!.map((item: any, i: any) => (
                            <li key={i}>
                                <Link to={item.link}>{item.title}</Link>
                            </li>
                        ))
                    }
                </ul>
            </div>

        )
    }

    private renderMainMenu() {
        const nav = navItems;
        return (
            <nav className="header__main-menu nav">
                {
                    nav.map((item: INavItems, i: number): JSX.Element => {
                        if (item.subNavItems) {
                            return this.renderSubNav(item, i);
                        } else {
                            return (
                                <Link smooth={true} className={`nav__item ${this.isActive(item.link)}`} to={item.link} key={i}>{item.title}</Link>
                            );
                        }
                    })
                }
            </nav>
        )
    }

    private renderHeaderRow(): JSX.Element {
        const width = window.screen.width;
        const isOpen = this.state.isDisplayedMobileNav ? "open" : "close"
        if (width <= 768) {
            return (
                <div className="header__row">
                    <Link className="header__logo" to="/" />
                    <button className={`header__hamburger ${isOpen}`} onClick={this.onClickHamburger} />
                </div>
            )
        }
        else {
            return (<div className="header__row">
                <Link className="header__logo" to="/" />
                <p className="header__text">Enterprise consulting and solutions</p>
                {this.renderServiceMenu()}
            </div>);
        }
    }

    private renderServiceMenu() {
        return (
            <nav className="header__service-menu nav">
                {
                    navServiceItems.map((item: INavItems, i: number): JSX.Element => {
                        if (item.subNavItems) {
                            return this.renderSubNav(item, i);
                        } else {
                            return (
                                <Link className={`nav__item ${this.isActive(item.link)}`} to={item.link} key={i}>{item.title}</Link>
                            );
                        }
                    })
                }
            </nav>
        );
    }

    private onClickHamburger() {
        this.setState({ isDisplayedMobileNav: !this.state.isDisplayedMobileNav });
    }

    private renderMobileNav(): JSX.Element {
        return (
            <div className="mobile__nav">
                <ul className="feedback__list">
                    <li className="feedback__item-phone">+49 (0) 6172 868449</li>
                    <li className="feedback__item-email">info@enconso.com</li>
                </ul>
                {this.renderMainMenu()}
                {this.renderServiceMenu()}
            </div>
        );
    }

    private isActive(link: string): `active` | `` {
        return window.location.pathname === link ? `active` : ``;
    }
}