export interface INavItems {
    title: string;
    link: string;
}

export const navItems: INavItems[] = [
    {
        title: 'Java / JavaScript',
        link: '/non-sap-solutions#enterprise-software-entwicklung',
    },
    {   
        title: 'SAP',
        link: `/sap-solutions`,
    },
    {
        title: 'SQL / No SQL Databases',
        link: `/uber-uns#unsere-expertisen`,
    },
    {
        title: '',
        link: `/`,
    },
    {
        title: 'Mobile Development',
        link: '/non-sap-solutions#mobile-entwicklung',
    },
    {
        title: 'Web',
        link: '/non-sap-solutions#web-entwicklung',
    },
    {
        title: 'Project Management Tools',
        link: '/uber-uns#unsere-expertisen',
    }
]