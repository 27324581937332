import { IStatementProps } from "src/components/shared/sections/Statement/Statement"
import { IOneColumnTextProps } from "src/components/shared/containers/OneColumnText/OneColumnText"

const statementProps: IStatementProps = {
    page: 'imprint',
    title: 'Impressum',
    subtitle: 'enconso GmbH', 
    microtitle: 'Enterprise consulting and solutions',
    text: `
        Hoelderlinweg 17b
        D-61350 Bad Homburg

        Sitz der Gesellschaft/Registered Office: Bad Homburg, Germany 
        Geschäftsführer / Managing Director: Igor Friedman 
        Registergericht / Commercial Register Bad Homburg No HRB 11364

        USt-ID-Nr.: DE 266 199 003`
}

const oneColumnTextPropsList: IOneColumnTextProps[] = [
    {title: `Urheberrecht`, text: `Die Veröffentlichungen auf diesen Seiten unterliegen dem deutschen Urheberrecht. Vervielfältigung, Bearbeitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung der enconso GmbH.`},
    {title: `Haftung für Inhalte`, text: `Die Informationen auf dieser Seite sind allgemeiner und informativer Art. Die Inhalte dieser Seiten werden mit größter Sorgfalt erstellt. Für Richtigkeit, Vollständigkeit und Aktualität dieser Inhalte kann jedoch keine Haftung übernommen werden.`},
]

export const imprintData = {
    statementProps,
    oneColumnTextPropsList
}




