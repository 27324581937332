import * as React from 'react';
import Title from 'src/components/shared/containers/Title/Title';
import './Implementation.scss';


const implementationProps = {
    title: `\n\nImplementierung`,
    subtext: `Auswahl zwischen lokaler und Cloud-Bereitstellung möglich, 
    wobei für die Cloud-Implementierung drei 
    Provider zur Verfügung stehen:`,
    list: [
        {
            srcImg: `/img/implementation-sap-bw4-hana/1.svg`,
            text: `SAP HANA Enterprise Cloud`
        },
        {
            srcImg: `/img/implementation-sap-bw4-hana/2.svg`,
            text: `Amazon Web Services`
        },
        {
            srcImg: `/img/implementation-sap-bw4-hana/3.svg`,
            text: `Microsoft Azure`
        },
    ]
}
export default class ImplementationSapBw4Hana extends React.Component {

    public render() {
        const title = implementationProps.title;
        const subtext = implementationProps.subtext;
        const list = implementationProps.list.map((item, i) => (
            <li className="implementation-sap-bw4-hana__item" key={i}>
                <div className="implementation-sap-bw4-hana__item-img-wrap">
                    <img alt="" src={item.srcImg} />
                </div>
                <p className="implementation-sap-bw4-hana__item-text">{item.text}</p>
            </li>
        ));
        
        return (
            <div className="implementation-sap-bw4-hana">
                <Title type="sub" theme="white" text={title} />
                <p className="implementation-sap-bw4-hana__subtext">{subtext}</p>
                <ul className="implementation-sap-bw4-hana__list">
                    {list}
                </ul>
            </div>
        );
    }
}
