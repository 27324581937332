import * as React from 'react';
import FormContacts from 'src/components/shared/components/FormContacts/FormContacts';
import ContentContainer from 'src/components/shared/containers/ContentContainer/ContentContainer';
import Elements from 'src/components/shared/containers/Elements/Elements';
import Feedback from 'src/components/shared/sections/Feedback/Feedback';
import Footer from 'src/components/shared/sections/Footer/Footer';
import Statement from 'src/components/shared/sections/Statement/Statement';
import { caseStudiesData } from './data';

export interface ICaseStudiesProps {
}

export default class CaseStudies extends React.Component<ICaseStudiesProps> {
  public render() {
    return (
      <div className='case-studies'>
        <Statement {...caseStudiesData.statementProps} />
        <FormContacts />
        <ContentContainer>
          <Elements {...caseStudiesData.elementProps} />
        </ContentContainer>
        <Feedback theme="blue" />
        <Footer />
      </div>
    );
  }
}
