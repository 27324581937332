import * as React from 'react';
import compAndBFData from 'src/components/pages-components/SapSolutions/SapS4Hana/ComponentsAndBF/data'
import './ComponentsAndBF.scss';
import CustomAccordion from './CustomAccordion/CustomAccordion';

export interface IComponentsAndBF {
    selectedListIndex: number;
}


export default class ComponentsAndBF extends React.Component<{}, IComponentsAndBF> {
    constructor(props: any) {
        super(props);

        this.state = {
            selectedListIndex: 0
        };
        this.selectList = this.selectList.bind(this);
    }

    public render() {
        return (
            <section className="components-and-business-functions-sap-s4hana">
                <h2 className="components-and-business-functions-sap-s4hana__title">Primäre Geschäftsfunktionen von SAP S/4HANA</h2>
                {compAndBFData.map((item, i) => (
                    <CustomAccordion 
                        {...item} 
                        key={i} 
                        index={i} 
                        selected={i === this.state.selectedListIndex && item.itemTitleList.length > 0}
                        selectList={this.selectList} 
                    />
                ))}
            </section>
        );
    }

    private selectList(index:number): void {
        if (index !== this.state.selectedListIndex) {
            this.setState({selectedListIndex: index});
        }
    }
}
