import * as React from 'react';
import './CardList.scss';

export interface ICardListProps {
    title: string;
    theme: string;
    listTheme: string;
    list: ICardList[];
}

interface ICardList {
    title?: string | null;
    text: string;
    srcImg: string;
}

export default class CardList extends React.Component<ICardListProps> {
    public renderList() {
        const items = this.props.list;
        return (
            <ul className="card-list__list">
                {items.map((item, i) => {
                    return (
                        <li className="card-list__item" key={i}>
                            <div className="card-list__item-img-wrap">
                                <img alt="" src={item.srcImg} />
                                {item.title && 
                                <h3 className="card-list__item-title">{item.title}</h3>}
                            </div>
                            <p className="card-list__item-text">{item.text}</p>
                        </li>
                    );
                })}
            </ul>
        );
    }

    public render() {
        const theme = `card-list--theme-${this.props.theme}`;
        const listTheme = `card-list--${this.props.listTheme}`;
        const title = this.props.title;
        const list = this.renderList();
        return (
            <div className={`card-list theme ${theme} ${listTheme}`}>
                <div className="card-list__wrap">
                    <h3 className="card-list__title">{title}</h3>
                    {list}
                </div>
            </div>
        );
    }
}
