import { IListWithСirclesProps } from "src/components/shared/sections/ListWithСircles/ListWithСircles";
import { IStatementProps } from "src/components/shared/sections/Statement/Statement";

const statementProps: IStatementProps = {
    title: `SAP BW/4 HANA`,
    subtitle: 'SAP-Solution zum Sammeln, Speichern und Analysieren von Daten auf der SAP-HANA-Plattform, ' + 
    'die eine einfache Verwaltung von Unternehmensdatenflüssen in Echtzeit und Entscheidungen auf übersichtlichter ' +
    'Datengrundlage ermöglicht. ',
    page: "sap-bw"
}

const listWithСirclesProps: IListWithСirclesProps = {
    title: `Vorteile der SAP-Cloudlösung:`,
    theme: `white`,
    list: [
        {
            srcImg: `/img/our-clients/1.jpg`,
            text: `Schnelle Implementierung`
        },
        {
            srcImg: `/img/our-clients/2.jpg`,
            text: `Kostensenkung`
        },
        {
            srcImg: `/img/our-clients/3.jpg`,
            text: `Maximale Leistung`
        }
        ,
        {
            srcImg: `/img/our-clients/4.jpg`,
            text: `Hyperscaling`
        }
    ]
}

const markedListProps = {
    list: [
        `Integration von Daten aus beliebigen Quellen (SAP, NonSAP, Big Data) in eine einzige Datenbank`,
        `Verarbeitung von strukturierten und unstrukturierten Informationen`,
        `Unbegrenzte Datenspeicherung und -verarbeitung`,
        `Business Intelligence und Transaktionsmanagement in Echtzeit`,
        `Objektmodellierung mit ADSO und CompositeProvider`,
        `Hochleistungsprozessierung`,
        `Komfortable Benutzeroberfläche sowie einfache Visualisierung von Berichten`,

    ]
}

export const SapBW4HanaData = {
    statementProps,
    listWithСirclesProps,
    markedListProps
}