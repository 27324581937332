import * as React from "react";
import './ServicesSRM.scss'

export default class ServicesSRM extends React.Component {
    public render() {
        return (
            <div className="services-srm section">
                <div className="services-srm__container container">
                    <h1 className="services-srm__title main-title">Enconso-Dienstleistungen in SAP SRM</h1>
                    <div className="services-srm__content">
                        <p className="services-srm__text services-srm__text--left">
                            Expertise in der Automatisierung und Beschaffungs-optimierung basierend auf 
                            10 Jahren Erfahrung sowie kontinuierlicher Verbesserung von SAP-Produkten.
                        </p>
                        <p className="services-srm__text services-srm__text--right">
                            Unsere Berater und Entwickler ermöglichen es Ihnen, ein effektives Beschaffungssystem für
                            Ihr Unternehmen zu erstellen und umzusetzen.
                            Wir schulen auch Ihre Mitarbeiter und bieten technischen Support.
                        </p>
                    </div>
                    
                </div>
            </div>
        );
    }
}