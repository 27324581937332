import * as React from 'react';
import './HowWeWork.scss';

export interface IHowWeWorkData {
    text: string[];
}

interface IHowWeWorkProps {
    data: IHowWeWorkData;
    theme: 'simple' | 'white';
}

export default class HowWeWork extends React.Component<IHowWeWorkProps> {
    public render() {
        if (this.props.theme === 'simple') {
            return (
                <div className="how-we-work__bottom">
                    <p className="how-we-work__sub-title">Wie wir arbeiten:</p>
                    <div className="how-we-work__text">
                        <ol> {this.renderList()} </ol>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="how-we-work__bottom white">
                    <p className="how-we-work__sub-title white">Wie wir arbeiten:</p>
                    <div className="how-we-work__text white">
                        <ol className="white"> {this.renderList()} </ol>
                    </div>
                </div>
            );
        }
    }
    private renderList(): JSX.Element[] {
        if (this.props.theme === 'simple') {
            return (
                this.props.data.text.map((item, i) => {
                    return (
                        <React.Fragment key={i}>
                            <li>{item}</li>
                            {this.props.data.text.length - 1 !== i ? <p /> : ''}
                        </React.Fragment>
                    );
                })
        );
        } else {
            return (
                this.props.data.text.map((item, i) => {
                    return (
                        <React.Fragment key={i}>
                            <li className="white">{item}</li>
                            {this.props.data.text.length - 1 !== i ? <p className="white" /> : ''}
                        </React.Fragment>
                    );
                })
            );
        }
    }
}