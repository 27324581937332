import * as React from 'react';
import MarkedList from 'src/components/shared/containers/MarkedList/MarkedList';
import './Development.scss';
import HowWeWork, { IHowWeWorkData } from './HowWeWork/HowWeWork';

interface IComputerspracheData {
    title?: string;
    icons: Array<{
        title? :string;
        url: string;
        /* размер иконки - 110px | 130px | 150px */
        size: 'little' | 'middle' | 'big';
    }>
}

export interface IDevelopmentProps {
    theme: 'blue' | 'white';
    title: string;

    dienstleistungen: string[];
    warumEnconso: string[];

    computersprache: IComputerspracheData[];
    backgroundImageURL: string;

    rightBlockText: string[];

    howWeWorkData: IHowWeWorkData;
}

export default class Development extends React.Component<IDevelopmentProps> {
    public render() {
        return (
            this.renderDevelopment()
        );
    }




    private renderDevelopment(): JSX.Element {
        return (
            <div className={`development${this.getTheme()}`} id={this.props.title.replace(/ /g, '-').toLowerCase()}
                style={{ 
                    backgroundImage: `url('${window.screen.width > 768 ? this.props.backgroundImageURL : ''}')`,
                    backgroundSize:'cover' 
                    }}>
                <div className={`development__container container${this.getTheme()}`}>
                    <h1 className={`development__title${this.getTheme()}`}>{this.props.title}</h1>
                    <div className="development__content">
                        <div className="development__left">
                            <MarkedList type={this.invertThemeForMarkedList()} list={this.props.dienstleistungen} title='Dienstleistungen:' />
                            <MarkedList type={this.invertThemeForMarkedList()} list={this.props.warumEnconso} title='Warum ENCONSO:' />
                            <p className={`development__sub-title${this.getTheme()}`}>Computersprache:</p>
                            {this.renderComputersprache()}
                        </div>
                        <div className={`development__right${this.getTheme()}`}>
                            {this.props.rightBlockText.map((text, i) => (
                            <p key={i}>{text}</p>
                        ))}
                        </div>
                    </div>
                    <HowWeWork data={this.props.howWeWorkData} theme={this.props.theme === 'blue' ? 'white' : 'simple'} />
                </div>
            </div>
        );
    }
    private invertThemeForMarkedList() {
        return this.props.theme === 'blue' ? 'white' : 'blue';
    }
    private getTheme(): '' | ' white' {
        return this.props.theme === 'blue' ? '' : ' white';
    }
    private renderComputersprache(): JSX.Element {
        return (
            <ul className="development__icons">
                {this.props.computersprache.map((item, i) => (
                    <li key={i} className="development__icon-container">
                        {item.title ? <div className="development__icon-container__title">{item.title}</div>: ''}
                        {item.icons.map((icon, j) => (
                            <div className={`development__icon-item${this.getTheme()}`}>
                                <div key={j} className={'development__icon ' + icon.size} style={{background:`url('${icon.url}')  no-repeat center`}} />
                                <div className="development__icon__title">{icon.title}</div>
                            </div>
                        ))}
                    </li>
                ))}
            </ul>

        );
    }
}
