import * as React from 'react';
import './GoTo.scss'
import NominatedCardList from './NominatedCardList/NominatedCardList';


const listProps = {
    title: `Folgende Ausgangssituationen liegen Ihnen vor:`,
    list: [
        {
            title: `Komplette Neuinstallation:`,
            text: `Sie verwenden eine Non-SAP-Plattform und möchten auf SAP BW / 4HANA upgraden`
        },
        {
            title: `Migration:`,
            text: `Sie verwenden ältere SAP-Applikationen und möchten SAP BW / 4HANA migrieren`
        },
        {
            title: `Transformation:`,
            text: `Ihr Informationssystem ist fragmentiert und enthält Solutions von mehreren Anbietern, die Sie vereinheitlichen wollen`
        },
    ]
}


export default class GoTo extends React.Component {

    public render() {
        return (
            <section className="go-to-sap-bw4-hana section">
                <div className="go-to-sap-bw4-hana__wrap container">
                    <h2 className="go-to-sap-bw4-hana__title">Übergang zu SAP BW / 4HANA</h2>
                    <NominatedCardList {...listProps} />
                    <p className="go-to-sap-bw4-hana__subtext">In jedem Fall übertragen wir Daten verlustfrei und unterbrechen laufende Geschäftsprozesse minimal wie möglich</p>
                </div>
            </section>
        );
    }
    }
