import * as React from "react";
import './GuideSAP.scss'

export default class GuideSAP extends React.Component{
    public render() {
        return (
            <div className="guide-sap section">
                <div className="guide-sap__container container">
                    <h1 className="guide-sap__title">Enconso ist führend im SAP-Umfeld und bietet Ansätze für alle Phasen in der Unternehmensautomatisierung an.</h1>
                    <h1 className="guide-sap__sub-title">Unsere SAP-Dienstleistungen:</h1>
                    <ul className="guide-sap__list">
                        <li className="guide-sap__item guide-sap__item--consalting">Beratung</li>
                        <li className="guide-sap__item guide-sap__item--adaptation">Implementierung sowie Anpassung von SAP-Produkten</li>
                        <li className="guide-sap__item guide-sap__item--development">Kundenspezifische Lösungen</li>
                        <li className="guide-sap__item guide-sap__item--optimization">Optimierung / Prozessierung bestehender Lösungen</li>
                        <li className="guide-sap__item guide-sap__item--support">Projektsupport nach der Implementierung</li>
                    </ul>
                </div>
            </div>
        );
    }
}