import * as React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ContentContainer from "src/components/shared/containers/ContentContainer/ContentContainer";
import MarkedList from "src/components/shared/containers/MarkedList/MarkedList";
import Title from "src/components/shared/containers/Title/Title";
import { markedListData } from "./data";
import './OurClients.scss';



export default class OurClients extends React.Component {
    public render() {
        return (
            <section className="our-clients">
                <ContentContainer>
                    <Title type='main' theme='blue' text='Unsere Kunden' />
                    <MarkedList {...markedListData}  />
                </ContentContainer>
            </section>
        );
    }

    public onClickPreButton() {
        return;
    }
    public onClickNextButton() {
        return;
    }
} 