export interface INavItems {
    title: string;
    link: string;
    subNavItems?: Array<{ 
            title: string;
            link: string;
        }>;
    theme?: string;
}

/*const PAGE_NOT_EXIST = '/';*/
export const navItems: INavItems[] = [
    {
        title: 'Über uns',
        link: '/uber-uns',
    },
    {   
        title: 'SAP-Solutions',
        link: `/sap-solutions`,
        subNavItems: [
            { 
                title: `SAP S4/HANA`,
                link: `/sap-solutions/sap-s4hana`
            },
            { 
                title: `SAP SRM`,
                link: `/sap-solutions/sap-srm`
            },
            { 
                title: `SAP Ariba`,
                link: `/sap-solutions/sap-ariba`
            },
            { 
                title: `SAP BW/4 HANA`,
                link: `/sap-solutions/sap-bw-4hana`
            },
        ],
        theme: `sub-menu-sap-solutions`
    },
    {
        title: 'NonSAP-Solutions',
        link: `/non-sap-solutions`,
    },
    {
        title: 'Services',
        link: `/services`,
    },
    {
        title: 'Case Studies',
        link: '/case-studies',
    },
    {
        title: 'Kontakt',
        link: '#feedback-contacts',
    },
]

export const navServiceItems: INavItems[] = [
    {
        title: 'Karriere',
        link: 'karriere',
    },
    {
        title: 'Impressum',
        link: '/impressum',
    },
    {
        title: 'Datenschutz',
        link: '/datenschutz',
    },
]