import { IMarkedListProps } from "src/components/shared/containers/MarkedList/MarkedList";

export const markedListData: IMarkedListProps = {
    title: 'Dienstleistungen:',
    list: [
        'Führendes Telekommunikationsunternehmen in Europa mit weltweitem Footprint',
        'Global top-3 Softwareunternehmen zur Abwicklung von Geschäftsprozessen in Unternehmen',
        'Großer, europäischer Betreiber von Privatfernsehen und -radio',
        'Deutscher, gemeinnütziger Verein im medizinischen Versorgungsbereich',
        'Führendes, deutsches Marktforschungsinstitut',
        'Führendes, europäisches Industrieunternehmen in der Flugzeugindustrie',
        'Führende, europäische Airline'
    ],
    type: 'white'
}