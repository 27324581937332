import { ICardListProps } from "src/components/shared/containers/CardList/CardList";
import { ITwoColumnTextProps } from "src/components/shared/containers/TwoColumnText/TwoColumnText";
import { IStatementProps } from "src/components/shared/sections/Statement/Statement";

const statementData: IStatementProps = {
    title: `SAP Ariba`,
    subtitle: `Maximierung einer effizienten Beschaffung (Procurement)`,
    page: "sap-ariba"
}

const twoColumnTextProps: ITwoColumnTextProps = {
    title: 'SAP Ariba',
    type: 'marked',
    // TODO
    leftBlock: [
        'Next-Gen Supply-Chain-Management mit der SAP Ariba Suite und der Ariba Network Global Business Platform.',
        'Sukzessive Prozessoptimierung des Beschaffungssystems Ihres Unternehmens dank des modularen Aufbaus von SAP Ariba.',
    ],
    rightBlock: [
        'Enconso ist seit 2009 im Beschaffungsbereich tätig und bietet individuelle Lösungen für Kunden unter Berücksichtigung des Budgets und der Prioritäten an.'
    ]
}

const cardListProps : ICardListProps = {
    title: `Funktionen von SAP Ariba`,
    theme: `blue`,
    listTheme: `without-title`,
    list: [
        {
            text: `Cloud-basierte Echtzeit-Beschaffungsplattform`,
            srcImg: `/img/card-list/sap-ariba/1.svg`
        },
        {
            text: `Automatisiertes Management von Konten, Bestellungen und Quittungen`,
            srcImg: `/img/card-list/sap-ariba/2.svg`
        },
        {
            text: `Datenkonsolidierung, Performance-Analysen, Berichterstattung`,
            srcImg: `/img/card-list/sap-ariba/3.svg`
        },
        {
            text: `Kontinuierliche Aktualisierung der Lieferantenbasis sowie Risikobewertung`,
            srcImg: `/img/card-list/sap-ariba/4.svg`
        },
        {
            text: `Bewertung und Segmentierung von Lieferanten sowie Suche nach besten Lieferbedingungen`,
            srcImg: `/img/card-list/sap-ariba/5.svg`
        },
        {
            text: `Bequemes Arbeiten mit Verträgen, Katalogen, Ausschreibungen (Tenders), Auktionen`,
            srcImg: `/img/card-list/sap-ariba/6.svg`
        },
    ]
}

export const SapAribaData = {
    statementData,
    twoColumnTextProps,
    cardListProps
}