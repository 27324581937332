import * as React from 'react';
import './App.scss';
import Root from './containers/Root/Root';

class App extends React.Component {
  public render() {
    return (
      <Root />
    );
  }
}

export default App;
