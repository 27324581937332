import * as React from "react";
import NonSAPSolutions from "src/components/pages-components/Main/ExaminationEnconso/NonSAPSolutions/NonSAPSolutions";
import SAPSolutions from "src/components/pages-components/Main/ExaminationEnconso/SAPSolutions/SAPSolutions";
import Services from "src/components/pages-components/Main/ExaminationEnconso/Services/Services";

export default class ExaminationEnconso extends React.Component{
    public render() {
        return (
            <div >
                <SAPSolutions />
                <NonSAPSolutions />
                <Services />
            </div>
        );
    }
}