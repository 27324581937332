import * as React from "react";
import KeyEffect from "src/components/pages-components/SapSolutions/SapAriba/KeyEffect/KeyEffect";
import Resolve from "src/components/pages-components/SapSolutions/SapAriba/Resolve/Resolve";
import FormContacts from "src/components/shared/components/FormContacts/FormContacts";
import CardList from "src/components/shared/containers/CardList/CardList";
import ContentContainer from "src/components/shared/containers/ContentContainer/ContentContainer";
import TwoColumnText from "src/components/shared/containers/TwoColumnText/TwoColumnText";
import Feedback from "src/components/shared/sections/Feedback/Feedback";
import Footer from "src/components/shared/sections/Footer/Footer";
import Statement from "src/components/shared/sections/Statement/Statement";
import { SapAribaData } from "./data";



export default class SapAriba extends React.Component {
    public render() {

        return (
            <>
                <Statement {...SapAribaData.statementData}/>
                <ContentContainer>
                    <TwoColumnText {...SapAribaData.twoColumnTextProps} />
                </ContentContainer>
                <CardList {...SapAribaData.cardListProps}/>
                <KeyEffect />
                <Resolve />
                <FormContacts />
                <Feedback theme="white" />
                <Footer />  
            </>
        );
    }
}