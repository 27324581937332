import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Title from "src/components/shared/containers/Title/Title";
import './SAPSolutions.scss';

export default class SAPSolutions extends React.Component{
    public render() {
        return (
            <section className="sap-solutions section">
                <div className="sap-solutions__container container">
                    <Title type= 'main' text='Unsere Kompetenzen' />
                    <h3 className="sap-solutions__title">SAP-Solutions</h3>
                    
                    <div className="sap-solutions__list">
                        <ul className="sap-solutions__list__column">
                            <li>SAP S4/HANA</li>
                            <li>SAP SRM</li>
                            <li>SAP Ariba</li>
                            <li>SAP BW/4 HANA</li>
                        </ul>
                    </div>
                    
                    <Link to='sap-solutions#topAnchor'><button className="button">Mehr Info</button></Link>
                </div>
            </section>           
        );
    }
}