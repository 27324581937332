
import { IElementProps } from "src/components/shared/containers/Elements/Elements"
import { IStatementProps } from "src/components/shared/sections/Statement/Statement"

const statementProps: IStatementProps = {
    page: 'about-us',
    title: 'Über uns',
    subtitle: 'Was Sie über enconso als One-Stop Shop wissen müssen'
}

const elementsProps: IElementProps = {
    elements:[
        {
            elementProps: {
                leftBlock: [
                    'enconso GmbH (enterprise consulting and solutions) ist ein bereits seit 10 Jahren erfolgreich etabliertes IT-Unternehmen in Europa mit Präsenzen in Deutschland und Osteuropa.',
                    'Als IT-Outsourcing Spezialist bietet enconso sämtliche IT Services mit einem Fokus auf Informationssysteme, Datenbanken, Desktop- und webbasierte Anwendungen an.',
                    'Hierbei setzt enconso primär auf die agile Softwareentwicklung (z.B. SCRUM) als Ansatz im Entwicklungsprozess, der im Gegensatz zu den oft bürokratisch angesehenen traditionellen Softwareentwicklungsprozessen flexibler, schlanker und transparenter ist. Der Vorteil dieses kostengünstigen Ansatzes wird von Unternehmen geschätzt und weitgehend bevorzugt.'
                ],
                rightBlock: [
                    'Warum enconso?',
                    'Durch unsere jahrelange Zusammenarbeit mit einer Vielzahl an weltweit agierenden Blue-Chip Kunden (Referenzen auf Nachfrage) konnten wir wertvolle Erfahrungen sammeln, die wir gerne an unseren Kunden weitergeben. Unsere IT-Services entsprechen den modernsten Qualitätsstandards und unsere Fachkompetenz sowie technischen Fähigkeiten ermöglichen es Ihnen, die komplexesten Projekte mit den neuesten Technologien durchzuführen.',
                    'Unsere Berater und Developer sind langjährig erfahrene (ca. zehn Jahre durchschnittl.), sektorunabhängige Experten in ihrem Entwicklungsumfeld und waren im Laufe ihrer Karriere auf etwa sechs bis acht mittelfristigen sowie mehrjährigen Projekten mandatiert. Viele Endkunden, die zufrieden mit unserem Service waren, haben oft eine Verlängerung von Projekten und/ oder Neuprojekte angeboten und somit eine langfristige Partnerschaft mit enconso etabliert.'
                ]
            },
            type: 'TwoColumnText'
        },
        {
            elementProps: {
                text:'\nUnsere Anwendungsbereiche in SAP: ',
                type:'sub'
            },
            type: 'MainTitle'
        },
        {
            elementProps: {
                title:'',
                list: [
                    'SAP Procurement',
                    'SAP Business Intelligence',
                    'SAP Development',
                    'Mobile applications'
                ]
            },
            type: 'MarkedList'
        },
        {
            elementProps: {
                text:'Unsere Anwendungsbereiche in Non-SAP:',
                type:'sub'
            },
            type: 'MainTitle'
        },
        {
            elementProps: {
                title:'',
                list: [
                    'Java/ JavaScript',
                    'SQL/ NoSQL Databases',
                    'Mobile Development',
                    'Web Development',
                    'Project Management Tools'
                ]
            },
            type: 'MarkedList'
        },
        {
            elementProps: {
                text:'Unsere Expertisen:',
                type:'sub',
                id:'unsere-expertisen'
            },
            type: 'MainTitle'
        },
        {
            elementProps: {
                title:'',
                list: [
                    'SAP: SAP S4/ HANA, SRM, Ariba, BW, BW/4 HANA',
                    'SAP Development: SAPUI5, Fiori, ABAP/4 + ABAP-OO, Workflow, ALE/IDOC, BDOC, SOA, WebServices, XML, RFC, WebDynpro, Adobe Print Forms, SAP Formulare',
                    'Java: JEE, Servlet, JSP, EJB, JMS, JAXB, REST, JPA/Hibernate,myBatis, GWT, Spring, Guice, Struts J2SE, Swing',
                    // eslint-disable-next-line
                    'JavaScript: jQuery + Ajax, Angular 2-6, React, ExtJS, AngularJS, NodeJS, OpenLayers, Google Maps, Yandex Maps, OpenStreetMaps',
                    'SQL: MySQL, MS SQL, PostgreSQL, SQLite, Oracle',
                    'NoSQL: MongoDB, Neo4j, ElasticSearch',
                    'Mobile: Android SDK, iOS SDK, iCloud, PhoneGap/Cordova, J2ME, LWUIT',
                    'Web: HTML5, CSS, SASS, Bootstrap',
                    'Project Management: Apache Maven, Gradle, Jenkins CI, JIRA, Redmine',
                    '…und viele mehr…'
                ]
            },
            type: 'MarkedList'
        },
        {
            elementProps: {
                text: `Flexibilität und Zuverlässigkeit gehören zu den enconso Grundprinzipien. Unser Deutschland-Team ist bundesweit einsetzbar und mit der Projektdauer flexibel. Sofern erwünscht, können wir zusätzlich zur Onsite-Beratung und -Entwicklung auch eine hochkompetente und kostengünstige Offshore-Alternative anbieten. Auch wenn Sie soweit noch keine Offshore-Dienstleistungen in Anspruch genommen haben, gewährleistet dieser Service aus unserer jahrelangen Erfahrung ähnlich zufriedenstellende Ergebnisse wie durch den Service vor Ort. Dies eignet sich vor allem für junge Unternehmen hervorragend, die an eine strikte Budgetierung gebunden sind.`,
            },
            type: 'OneColumnText'
        },
        {
            elementProps: {
                text:'Unsere Dienstleistungen:',
                type:'sub'
            },
            type: 'MainTitle'
        },
        {
            elementProps: {
                title:'',
                list: [
                    'Beratung',
                    'Projekteinführung',
                    'Implementierung',
                    'Anpassung',
                    'Modernisierung'
                ]
            },
            type: 'MarkedList'
        },
    ]
}

export const aboutUsData = {
    statementProps,
    elementsProps
}