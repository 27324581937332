import * as React from "react";
import GuideSAP from "src/components/pages-components/SapSolutions/SapLosungen/GuideSAP/GuideSAP";
import SmallInfoBlock from "src/components/pages-components/SapSolutions/SapLosungen/SmallInfoBlocks/SmallInfoBlocks";
import FormContacts from "src/components/shared/components/FormContacts/FormContacts";
import CardList from "src/components/shared/containers/CardList/CardList";
import Feedback from "src/components/shared/sections/Feedback/Feedback";
import Footer from "src/components/shared/sections/Footer/Footer";
import Statement from "src/components/shared/sections/Statement/Statement";
import { SapLosungenData } from "./data";


export default class SAPLosungen extends React.Component{
    
    public render() {
        return (
            <>
                <Statement {...SapLosungenData.statementProps}/>
                <FormContacts />
                <CardList {...SapLosungenData.cardListProps} />
                <SmallInfoBlock {...SapLosungenData.smallInfoBlockProps} />
                <GuideSAP />
                <Feedback theme="blue" />
                <Footer />
            </>
        );
    }
}

