import * as React from "react";
import './MarkedList.scss'

export interface IMarkedListProps {
    title?: string;
    list: string[];
    type?: 'blue' | 'white' | 'blue-and-white';
}

export default class MarkedList extends React.Component<IMarkedListProps> {
    private type = this.props.type ? this.props.type : '';
    public render() {
        
        return (
            <div className={`marked-list ${this.type}`}>
                <div className="marked-list__wrap">
                    {this.props.title === '' || this.props.title === null ? '' : <h3 className={`marked-list__title ${this.type}`}>{this.props.title}</h3>}
                    <ul className="marked-list__list">
                        {this.renderList()}
                    </ul>
                </div>
            </div>
        );
    }
    private renderList(): JSX.Element[] {
        return this.props.list.map((item, i) => (
            <li className={`marked-list__item ${this.type}`} key={i}>{item}</li>
        ))
    }
}