import { IElementProps } from "src/components/shared/containers/Elements/Elements"
import { IStatementProps } from "src/components/shared/sections/Statement/Statement"

const statementProps: IStatementProps = {
    page: 'case-studies',
    title: 'Case Studies',
    subtitle: 'Ausgewählte Fallstudien, die enconsos Expertise bekräftigen'
}

const elementProps: IElementProps = {
    elements: [
        {
            elementProps: {
                text: 'Ausgewählte Fallstudien:\n\n',
                type:'sub'
            },
            type: 'MainTitle'
        },
        {
            elementProps: {
                title: 'Optimierung von Geschäftsprozessen mit SAP SRM ',
                list: [
                    'Prozessoptimierung über SAP SRM (Applikationsdesign, Erstellung von Solution- und technischen Design Dokumenten für SRM und MM Prozessen, Implementierung von Change Requests etc.) für einen ausländischen SAP Kunden'
                ],
                type: 'blue-and-white',
            },
            type: 'MarkedList'
        },
        {
            elementProps: {
                title: 'SAP Business Warehouse mit SAP BW 7.x ',
                list: [
                    'Konzeptionierung, Modellierung und Implementierung der BW Architektur für die Durchlaufzeiten im Einkaufsprozess, Anbindung des Sourcing Systems an Business Intelligence, Modellierung für das operative Monitoring in SAP SRM',
                    'Reporting von SRM Beleghistorie im BW, Auswertungen zum Monitoren von offenen Prozessen im Einkaufsumfeld (Backlog), Datenvalidierung, Produktionssupport'
                ],
                type: 'blue-and-white',
            },
            type: 'MarkedList'
        },
        {
            elementProps: {
                title: 'Mobile Banking App',
                list: [
                    'Die Anwendung ermöglicht es Kunden, den Kontostand von Bankkarten und Einzahlungen zu prüfen, vergangene Transaktionen anzuzeigen, Überweisungen zu tätigen, Bankstandorte auf der Karte zu suchen (einschließlich der Geschäftsstellen von Bankpartnern) etc.',
                    'Verwendete Programme/ Bibliotheken: Spring für Android, Picasso, Retrofit, Okhttp, Gson'
                ],
                type: 'blue-and-white',
            },
            type: 'MarkedList'
        },
        {
            elementProps: {
                title: 'CRM-System - Warteschlangen-Verarbeitungssystem mit Multithreading',
                list: [
                    'Synchronisation mit Accounting-Software, Online-Kommunikation, Chatting, Zuweisung von Werbebannern (sofern erforderlich)',
                    'Verwendete Programme/ Bibliotheken: jQuery, SASS, JSP, PostgreSQL, MongoDB, Hibernate, Spring)'
                ],
                type: 'blue-and-white',
            },
            type: 'MarkedList'
        },
        {
            elementProps: {
                title: 'Anwendung zur Ermöglichung von Investitionen in Start-Ups und kleineren Unternehmen (b2c - Java)',
                list: [
                    'Offene Investitionsplattform, auf der Benutzer in ausgeschriebene Projekte investieren oder eigene Projekte anbieten können',
                    'Eine besondere Anforderung im Rahmen dieses Projektes ist die Implementierung einer Micro-Service Architektur, die für speziell hohe Belastungen ausgelegt sein muss',
                    'Verwendete Programme/ Bibliotheken: Spring Boot, JMS, Docker, PostgreSQL, MongoDB'
                ],
                type: 'blue-and-white',
            },
            type: 'MarkedList'
        },
        {
            elementProps: {
                title: 'Integriertes System für einen Blumenlieferdienst (b2c - Java)',
                list: [
                    'Integration mit einem Payment-Gateway (Sberbank). Integration mit einem Zusteller-Gateway (Gett Taxi), Integration mit einem Buchhaltungssystem (1C)'
                ],
                type: 'blue-and-white',
            },
            type: 'MarkedList'
        },
        {
            elementProps: {
                title: 'Verkehrskontrollsystem ',
                list: [
                    'Überwachungs- und Verkehrskontrollsystem für den öffentlichen Verkehr.',
                    'Verwendete Programme/ Bibliotheken: Java, MyBatis, J2ME, Android, jQuery, C++, WinCE'
                ],
                type: 'blue-and-white',
            },
            type: 'MarkedList'
        },
        {
            elementProps: {
                title: '…Und viele weitere spannende Projekte… ',
                list: [],
                type: 'blue-and-white',
            },
            type: 'MarkedList'
        },
        {
            elementProps: {
                text: 'Ausgewählte Endkunden',
                type:'sub'
            },
            type: 'MainTitle'
        },
        {
            elementProps: {
                list: [
                    'Führendes Telekommunikationsunternehmen in Europa mit weltweitem Footprint',
                    'Global top-3 Softwareunternehmen zur Abwicklung von Geschäftsprozessen in Unternehmen',
                    'Großer, europäischer Betreiber von Privatfernsehen und -radio',
                    'Deutscher, gemeinnütziger Verein im medizinischen Versorgungsbereich',
                    'Führendes, deutsches Marktforschungsinstitut',
                    'Führendes, europäisches Industrieunternehmen in der Flugzeugindustrie',
                    'Führende, europäische Airline',
                ],
            },
            type: 'MarkedList'
        }
    ]
}

export const caseStudiesData = {
    statementProps,
    elementProps
}