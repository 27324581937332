import * as React from 'react';
import ComponentsAndBF from 'src/components/pages-components/SapSolutions/SapS4Hana/ComponentsAndBF/ComponentsAndBF';
import InstallingSapS4Hana from 'src/components/pages-components/SapSolutions/SapS4Hana/Installing/Installing';
import FormContacts from 'src/components/shared/components/FormContacts/FormContacts';
import CardList from 'src/components/shared/containers/CardList/CardList';
import ContentContainer from 'src/components/shared/containers/ContentContainer/ContentContainer';
import TwoColumnText from 'src/components/shared/containers/TwoColumnText/TwoColumnText';
import Feedback from 'src/components/shared/sections/Feedback/Feedback';
import Footer from 'src/components/shared/sections/Footer/Footer';
import Statement from 'src/components/shared/sections/Statement/Statement';
import { SapS4HanaData } from './data';

export default class SapS4Hana extends React.Component {
    public render() {
        return (
            <div>
                <Statement {...SapS4HanaData.statementProps} />
                <ContentContainer>
                    <TwoColumnText {...SapS4HanaData.twoColumnTextProps} />
                </ContentContainer>
                {window.screen.width > 768 ? <CardList {...SapS4HanaData.cardListProps} /> :''}
                <ComponentsAndBF />
                {/*<SubscribeToOurNewsletter />*/}
                <InstallingSapS4Hana />
                <FormContacts />
                <Feedback theme="blue" />
                <Footer />
            </div>
        );
    }
}
