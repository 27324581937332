import * as React from "react";
import SmallInfoBlock from "src/components/pages-components/SapSolutions/SapLosungen/SmallInfoBlocks/SmallInfoBlocks";
import FormContacts from "src/components/shared/components/FormContacts/FormContacts";
import Feedback from "src/components/shared/sections/Feedback/Feedback";
import Footer from "src/components/shared/sections/Footer/Footer";
import Statement from "src/components/shared/sections/Statement/Statement";
import { ServicesData } from "./data";


export default class Services extends React.Component{
    
    public render() {
        return (
            <>
                <Statement {...ServicesData.statementProps}/>
                <FormContacts />
                <SmallInfoBlock {...ServicesData.smallInfoBlockProps} />
                <Feedback theme="white" />
                <Footer />
            </>
        );
    }
}

