import * as React from "react";
import GoTo from "src/components/pages-components/SapSolutions/SapBw4Hana/GoTo/GoTo";
import Implementation from "src/components/pages-components/SapSolutions/SapBw4Hana/Implementation/Implementation";
import FormContacts from "src/components/shared/components/FormContacts/FormContacts";
import ContentContainer from "src/components/shared/containers/ContentContainer/ContentContainer";
import MarkedList from "src/components/shared/containers/MarkedList/MarkedList";
import Title from "src/components/shared/containers/Title/Title";
import Feedback from "src/components/shared/sections/Feedback/Feedback";
import Footer from "src/components/shared/sections/Footer/Footer";
import ListWithCircles from "src/components/shared/sections/ListWithСircles/ListWithСircles";
import Statement from "src/components/shared/sections/Statement/Statement";
import { SapBW4HanaData } from "./data";

export default class SapBw4Hana extends React.Component{
    public render() {
        return (
            <>
                <Statement {...SapBW4HanaData.statementProps}/>
                <ContentContainer>
                    <Title theme="white" type='sub' text='Funktionen von SAP BW/4HANA' />
                    <MarkedList {...SapBW4HanaData.markedListProps}/>
                    <Implementation />
                </ContentContainer>
                <ListWithCircles theme="white" {...SapBW4HanaData.listWithСirclesProps}/>
                <GoTo />
                <FormContacts />
                <Feedback theme="white" />
                <Footer />  
            </>
        );
    }
}