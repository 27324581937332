import * as React from "react";
import ExaminationEnconso from "src/components/pages-components/Main/ExaminationEnconso/ExaminationEnconso";
import IntelligentITSolutions from "src/components/pages-components/Main/IntelligentITSolutions/IntelligentITSolutions";
import OurClients from "src/components/pages-components/Main/OurClients/OurClients";
import FormContacts from "src/components/shared/components/FormContacts/FormContacts";
import Feedback from "src/components/shared/sections/Feedback/Feedback";
import Footer from "src/components/shared/sections/Footer/Footer";
import ListWithCircles from "src/components/shared/sections/ListWithСircles/ListWithСircles";
import Statement from "src/components/shared/sections/Statement/Statement";
import { MainData } from "./data";

export default class Main extends React.Component{
    
    public render() {
        return (
            <>
                <Statement {...MainData.statementProps}/>
                <IntelligentITSolutions />
                <ListWithCircles {...MainData.ListWithCirclesProps} />
                <ExaminationEnconso />
                <OurClients /> 
                {/*<Recall />*/}
                <FormContacts />
                <Feedback theme="white" />
                <Footer />  
            </>
        );
    }
}