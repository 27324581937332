import * as React from 'react';
import FormContacts from 'src/components/shared/components/FormContacts/FormContacts';
import ContentContainer from 'src/components/shared/containers/ContentContainer/ContentContainer';
import Elements from 'src/components/shared/containers/Elements/Elements';
import Feedback from 'src/components/shared/sections/Feedback/Feedback';
import Footer from 'src/components/shared/sections/Footer/Footer';
import Statement from 'src/components/shared/sections/Statement/Statement';
import { aboutUsData } from './data';

export interface IAboutUsProps {
}

export default class AboutUs extends React.Component<IAboutUsProps> {
  public render() {
    return (
      <div>
        <Statement {...aboutUsData.statementProps} />
        <FormContacts />
        <ContentContainer>
          <Elements {...aboutUsData.elementsProps} />
        </ContentContainer>
        <Feedback theme="blue" />
        <Footer />
      </div>
    );
  }
}
