import * as React from "react";
import Development from "src/components/pages-components/CustomDevelopment/Development/Development";
import WhatPartnersGet from 'src/components/pages-components/CustomDevelopment/WhatPartnersGet/WhatPartnersGet';
import FormContacts from "src/components/shared/components/FormContacts/FormContacts";
import Feedback from "src/components/shared/sections/Feedback/Feedback";
import Footer from "src/components/shared/sections/Footer/Footer";
import Statement from "src/components/shared/sections/Statement/Statement";
import { CustomDevelopmentData } from "./data";


export default class CustomDevelopment extends React.Component{
    public render() {
        return (
            <>
                <Statement {...CustomDevelopmentData.statementProps}/>
                <WhatPartnersGet />
                {CustomDevelopmentData.developmentsProps.map((developmentProps, i) => (
                    <Development key={i} {...developmentProps} />
                ))}
                <FormContacts />
                <Feedback theme="white" />
                <Footer />  
            </>
        );
    }
}