import { IDevelopmentProps } from "src/components/pages-components/CustomDevelopment/Development/Development";
import { IStatementProps } from "src/components/shared/sections/Statement/Statement";

const statementProps: IStatementProps = {
    title: `Die kundenspezifische Entwicklung`,
    subtitle: `Wir bieten kundenspezifische Anwendungssoftwareentwicklung für verschiedene Geschäftsbereiche an, sowohl in der Web- als auch mobilen Anwendungsentwicklung  `,
    page: "custom-development"
}

const developmentsProps: IDevelopmentProps[] = [
    {
        theme: 'blue',
        title: 'Enterprise Software Entwicklung',
        dienstleistungen: [
            'Kundenspezifische Software-Produktentwicklung',
            'Integration von Unternehmenssoftware',
            'Software-Support und-Upgrade',
        ],
        warumEnconso: [
            'Nur relevante und zuverlässige Technologie',
            'Rationales Management - alle Spezialisten arbeiten in einem Team',
            'Erfahrung in der Entwicklung komplexer Projekte mit hoher Arbeitsbelastung und Produktivität',
            'Produkt- und Prozessqualitätskontrolle'
        ],
        computersprache: [
            {
                icons: [
                    {
                        title: 'Java',
                        url: '/img/development/icon-java-white.svg',
                        size: 'little',
                    }
                ]
            }
        ],
        backgroundImageURL: '/img/nonSAP/background-enterprise-software.jpg',
        rightBlockText: [
            `Die individuelle Entwicklung 
        lässt zu, die Besonderheiten 
        Ihres Business zu 
        berücksichtigen. `
        ],
        howWeWorkData: {
            text: [
                'Aufgabenanalyse',
                'Prototypenentwicklung',
                'Interfacedesign',
                'Codeerstellung und -prüfung',
                'Debugging',
                'Implementierung',
            ]
        }
    },
    {
        theme: 'white',
        title: 'Mobile Entwicklung',
        dienstleistungen: [
            'Unternehmensanwendungen (B2B)',
            'Benutzerdefinierte Anwendungen (B2C)',
            'iOS-Entwicklung',
            'Android-Entwicklung',
            'Plattformübergreifende Entwicklung',
            'Smartphones',
            'Tablets',
            'Laptops',
            'Smart Watch und vieles mehr',
        ],
        warumEnconso: [
            'Auf Ihren Anforderungen zugeschnittenes Design',
            'Anwendungseffizienz: Kombination aus Leistung und Energieeinsparung',
            'Vollständiger Entwicklungszyklus - von der Definition der Anforderungen' +
            + 'bis zur Launch auf Google Play und im App Store',
            'Produkt- und Prozessqualitätskontrolle'
        ],
        computersprache: [
            {
                icons: [
                    {
                        title: 'Java',
                        url:'/img/development/icon-java-blue.svg',
                        size: 'little',
                    },
                    {
                        title: 'Swift',
                        url:'/img/development/icon-swift-blue.svg',
                        size: 'little',
                    },
                ]
            }
        ],
        backgroundImageURL: '/img/nonSAP/background-mobile-entwicklung.jpg',
        rightBlockText: [
            `In einer Welt, die von mobilen Technologien beherrscht wird, dürfen Sie sich die Vorteile der mobilen Entwicklung nicht entgehen lassen.`,
            `Enconso hilft Ihrem Unternehmen, Teil der mobilen Bewegung zu werden.`
        ],
        howWeWorkData: {
            text: [
                'Aufgabenanalyse',
                'Prototypenentwicklung',
                'Code- und Interface-Erstellung',
                'Testing',
                'Platzierung in App Stores',
            ]
        }
    },
    {
        theme: 'blue',
        title: 'Web Entwicklung',
        dienstleistungen: [
            'Unternehmensanwendungen und -dienste',
            'Beliebige Website: von der Landing Page zum Portal',
            'UX/UI-Design',
            'Front-end',
            'Back-end',
        ],
        warumEnconso: [
            'Flexibler Technologieeinsatz je nach Projektkomplexität',
            'Interaktivität - Ressourcen interagieren mit Benutzern',
            'Anpassungsfähigkeit - Anwendungen mit optimaler Bildschirmanpassung, auch auf mobile Geräte',
            'Zuverlässigkeit und Performance bei jeder Belastung'
        ],
        computersprache: [
            {
                title: 'Front-end:',
                icons: [
                    {
                        title: 'HTML',
                        url:'/img/development/icon-html-white.svg',
                        size: 'little',
                    },
                    {
                        title: 'JavaScript',
                        url:'/img/development/icon-js-white.svg',
                        size: 'little',
                    },
                ]
            },
            {
                title: 'Back-end:',
                icons: [
                    {
                        title: 'Java',
                        url:'/img/development/icon-java-white.svg',
                        size: 'little',
                    },
                    {
                        title: 'PHP',
                        url:'/img/development/icon-php-white.svg',
                        size: 'middle',
                    },
                ]
            }
        ],
        backgroundImageURL: '/img/nonSAP/background-web-entwickl.jpg',
        rightBlockText: [
            `Web-Technologien sind essentiell, um Ihr Unternehmen global zu vermarkten. Enconso unterstützt Sie bei der Implementierung der geeigneten Web-Technologien.`
        ],
        howWeWorkData: {
            text: [
                'Aufgabenanalyse',
                'Prototypenentwicklung',
                'Design',
                'Content-Erstellung',
                'Layout und Programmierung',
                'Testing',
                'Platzierung auf einem Server',
            ]
        }
    },
]

export const CustomDevelopmentData = {
    statementProps,
    developmentsProps,
}

