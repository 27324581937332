import * as React from "react";
import './Resolve.scss'

export default class Resolve extends React.Component{
    public render() {
        return (
            <div className="resolve-sap-ariba">
                <div className="section">
                    <div className="container">
                    <h1 className="resolve-sap-ariba__title">Enconso-Dienstleistungen <br /> in SAP Ariba</h1>
                    <ul className="resolve-sap-ariba__list">
                        <li className="resolve-sap-ariba__item resolve-sap-ariba__item--1">
                            Erstellung und Implementierung benutzerdefinierten SAP-Ariba-Konfigurationen
                        </li>
                        <li className="resolve-sap-ariba__item resolve-sap-ariba__item--2">
                            SAP-Ariba-Integration in bestehendes Unternehmenssystem
                        </li>
                        <li className="resolve-sap-ariba__item resolve-sap-ariba__item--3">
                            Modernisierung / Erweiterung bestehender Software
                        </li>
                        <li className="resolve-sap-ariba__item resolve-sap-ariba__item--4">
                            Support für eingebettete Lösungen
                        </li>
                    </ul>
                    </div>
                </div>
            </div>
        );
    }
}