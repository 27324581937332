import { ICardListProps } from "src/components/shared/containers/CardList/CardList";
import { IStatementProps } from "src/components/shared/sections/Statement/Statement";

const statementProps : IStatementProps =  {
    title:`SAP SRM`,
    subtitle: `Effizientes Beschaffungsmanagement (Procurement)`,
    page: "sap-srm"
}

const cardListProps: ICardListProps = {
    title: `Vorteile von SAP SRM`,
    theme: `blue`,
    listTheme: `without-title`,
    list: [
        {
            title: ``,
            text: `Prozessautomatisierung zur Optimierung des Beschaffungszyklus`,
            srcImg: `/img/card-list/sap-srm/1.svg`
        },
        {
            title: ``,
            text: `Beschleunigung von Rückzahlungen`,
            srcImg: `/img/card-list/sap-srm/2.svg`
        },
        {
            title: ``,
            text: `Auswahl effektiver Partner`,
            srcImg: `/img/card-list/sap-srm/3.svg`
        },
        {
            title: ``,
            text: `Reduzierung der Lieferkosten`,
            srcImg: `/img/card-list/sap-srm/4.svg`
        },
        {
            title: ``,
            text: `Verbessertes Beschaffungsmanagement`,
            srcImg: `/img/card-list/sap-srm/5.svg`
        },
        {
            title: ``,
            text: `Höhere Profitabilität durch Effizienzsteigerung`,
            srcImg: `/img/card-list/sap-srm/6.svg`
        },
    ]
}

export const SapSRMData = {
    statementProps,
    cardListProps
}