import * as React from 'react';
import './NominatedCardList.scss';

export interface INominatedCardListProps {
    title: string;
    list: INominatedCardList[];
}

interface INominatedCardList {
    title: string | null;
    text: string;
}

export interface INominatedCardListState {
}

export default class NominatedCardList extends React.Component<INominatedCardListProps, INominatedCardListState> {
    constructor(props: INominatedCardListProps) {
        super(props);

        this.state = {
        }
    }
    public renderList() {
        const items = this.props.list;
        return (
            <ul className="nominated-card-list__list">
                {items.map((item, i) => {
                    return (
                        <li className="nominated-card-list__item" key={i}>
                            <h3 className="nominated-card-list__item-title">{item.title}</h3>
                            {item.text && <p className="nominated-card-list__item-text">{item.text}</p>}
                        </li>
                    );
                })}
            </ul>
        );
    }

    public render() {
        const title = this.props.title;
        const list = this.renderList();
        return (
            <div className={`nominated-card-list`}>
                <div className="nominated-card-list__wrap">
                    <h2 className="nominated-card-list__title">{title}</h2>
                    {list}
                </div>
            </div>
        );
    }
}
