import { IListWithСirclesProps } from "src/components/shared/sections/ListWithСircles/ListWithСircles";
import { IStatementProps } from "src/components/shared/sections/Statement/Statement";

const statementProps : IStatementProps =  {
    title: ` SAP\nJava/JavaScript\nSQL/No SQL Databases\nProject Management Tools\nMobile Development\nWeb`,
    page: "main"
}

const ListWithCirclesProps: IListWithСirclesProps = {
    title: `Unsere Vorteile für Partner`,
    theme: `blue`,
    list: [
            {
                srcImg: `/img/main-page/our-advantages/1.jpg`,
                text: `Jahrelange Expertise und Erfahrung mit den neuesten Technologien.`
            },
            {
                srcImg: `/img/main-page/our-advantages/2.jpg`,
                text: `Fokus auf vertrauensvolle Beziehungen zu Kunden als enconso-Erfolgsfaktor.`
            },
            {
                srcImg: `/img/main-page/our-advantages/3.jpg`,
                text: `Gewährleistung der zeitgerechten Ausführung von Aufgaben.`
            }
            ,
            {
                srcImg: `/img/main-page/our-advantages/4.jpg`,
                text: `Optimale Lösungen für eine faire Vergütungsstruktur.`
            }
        ]
}

export const MainData = {
    statementProps,
    ListWithCirclesProps
}