import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { navItems, navServiceItems } from "../Header/data";
import './Footer.scss'

export default class Footer extends React.Component {

    public render() {
        return (
            <section className="footer section">
                <div className="footer__container container">
                    <div className="footer__content">
                    <div className="footer__promo">
                        <div className="footer__logo" />
                        <p className="footer__copyright">Copyright © 2019 enconso GmbH</p>
                    </div>
                        <div className="footer__list">
                                {navItems.map((item,i) => <Link to={item.link + (item.link.includes('#') ? '' : '#topAnchor')} key={i}>{item.title}</Link>)}
                                {navServiceItems.map((item,i) => <Link to={item.link + '#topAnchor'} key={i}>{item.title}</Link>)}
                        </div>
                    </div>
                    
                </div>
            </section>
        );
    }
}