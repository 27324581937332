import { ISSmallInfoBlocksProps } from "src/components/pages-components/SapSolutions/SapLosungen/SmallInfoBlocks/SmallInfoBlocks";
import { ICardListProps } from "src/components/shared/containers/CardList/CardList";
import { IStatementProps } from "src/components/shared/sections/Statement/Statement";

const statementProps: IStatementProps = {
    title: `SAP-Lösungen`,
    subtitle: `Kombinierter Ansatz von SAP sowie Enconso-Expertise zur Entwicklung von individuellen Lösungen für jedes Unternehmen`,
    page: "sap-losungen",
}

const smallInfoBlockProps: ISSmallInfoBlocksProps = {
    blocks: [
        {
            title: `SAP S4/HANA`,
            info: `Einheitliches System zur Verwaltung von Unternehmensressourcen, Supply Chain, Beziehungen zu Kunden und Lieferanten auf einer Plattform.`,
            class: "SapS4Hana",
            link: "/sap-solutions/sap-s4hana"
        },
        {
            title: `SAP SRM`,
            info: `Optimierung und Automatisierung des gesamten Beschaffungszyklus - von der Planung bis zur Bezahlung. Marktanalyse und Auswahl effektiver sowie effizienter Lieferanten.`,
            class: "SapSrm",
            link: "/sap-solutions/sap-srm"
        },
        {
            title: `SAP Ariba`,
            info: `Maximierung einer effizienten Beschaffung (Procurement).`,
            class: "SapAriba",
            link: "/sap-solutions/sap-ariba"
        },
        {
            title: `SAP BW/4 HANA`,
            info: `Smart-Lösung zur Speicherung und Echtzeit-Verarbeitung von unbegrenzt vielen Unternehmensdaten.`,
            class: "SapBW4Hana",
            link: "/sap-solutions/sap-bw-4hana"
        }
    ]
};

const cardListProps : ICardListProps = {
    title: `SAP-Plattform für Ihr Unternehmen:\nDie Vorteile`,
    theme: `white`,
    listTheme: `without-icon`,
    list: [
        {
            title: `Einheitliches Informationssystem`,
            text: `für alle Prozesse und Abteilungen des Unternehmens`,
            srcImg: `/img/card-list/sap-s4hana/1.svg`
        },
        {
            title: `Modularer Systemaufbau`,
            text: `Prozessautomatisierung durch sukzessiver Ergänzung neuer Komponenten`,
            srcImg: `/img/card-list/sap-s4hana/2.svg`
        },
        {
            title: `Datenrelevanz`,
            text: `Transaktionsverarbeitung, Datenanalyse, Echtzeitprognose (für neue Produkte auf der HANA-Plattform)`,
            srcImg: `/img/card-list/sap-s4hana/3.svg`
        },
        {
            title: `Umfangreiche Analytics-Möglichkeiten`,
            text: `durch Verwendung künstlicher Intelligenz (AI) sowie virtueller Datenmodelle`,
            srcImg: `/img/card-list/sap-s4hana/4.svg`
        },
        {
            title: `Möglichkeiten der Implementierungen`,
            text: `basierend auf Branche sowie Unternehmensgröße`,
            srcImg: `/img/card-list/sap-s4hana/5.svg`
        },
    ]
}

export const SapLosungenData = {
    statementProps,
    smallInfoBlockProps,
    cardListProps
}