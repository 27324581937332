import * as React from 'react';
import ContentContainer from 'src/components/shared/containers/ContentContainer/ContentContainer';
import OneColumnText from 'src/components/shared/containers/OneColumnText/OneColumnText';
import Feedback from 'src/components/shared/sections/Feedback/Feedback';
import Statement from 'src/components/shared/sections/Statement/Statement';
import { careerData } from './data';
import Footer from 'src/components/shared/sections/Footer/Footer';
import FormContacts from 'src/components/shared/components/FormContacts/FormContacts';

export interface ICareerProps {
}

export default class Career extends React.Component<ICareerProps> {
  public render() {
    return (
      <div>
          <Statement {...careerData.statementProps}/>
          <ContentContainer>
            {careerData.oneColumnTextPropsList.map((item,i) => (<OneColumnText key={i} {...item} />))}
          </ContentContainer>
        <FormContacts />
        <Feedback theme="blue" />
        <Footer />
      </div>
    );
  }
}
