import * as React from "react";
import './Feedback.scss'
import MessageSent from "../MessageSent/MessageSent";

interface IFeedbackProps {
    theme: 'white' | 'blue';
}
interface IFeedbackState {
    isChecked: boolean;
    hasSent: boolean;
}

export default class Feedback extends React.Component<IFeedbackProps, IFeedbackState> {
    constructor(props: IFeedbackProps) {
        super(props);
        this.state = {
            isChecked: false,
            hasSent: false
        }

        this.onClickCheckBox = this.onClickCheckBox.bind(this);
        this.onClickSendMessage = this.onClickSendMessage.bind(this);
        this.onClickCloseMessage = this.onClickCloseMessage.bind(this);
    }

    public render() {
        const disabled = !this.state.isChecked ? 'button--disabled' : '';
        const classModifier = `feedback--${this.props.theme}`;
        return (
            <section>
            <section id={'feedback-contacts'} className={`feedback ${classModifier} section`}>
                <h2 className={`feedback__title main-title main-title--${this.props.theme}`}>Kontaktieren sie uns</h2>
                <div className="feedback__container">
                    <div className="feedback__content">
                        <div className="feedback__contacts">
                            <h3 className={`feedback__contacts-title title title--${this.props.theme}`}>Kontaktinformationen</h3>
                            <ul className="feedback__list">
                                <li className="feedback__item-phone">+49 (0) 6172 868449</li>
                                <li className="feedback__item-email">info@enconso.com</li>
                                <li className="feedback__item-adress">enconso GmbH<br />
                                    Enterprise consulting and solutions<br />
                                    Hoelderlinweg 17b<br/>
                                    D-61350 Bad Homburg</li>
                            </ul>
                        </div>
                    </div>
                    <div className="feedback__modal">
                        <h3 className={`feedback__modal-title title title--white`}>Schreiben Sie uns</h3>
                        <p className="feedback__info">Schreiben Sie uns über Ihr Projekt und unser Experte wird Sie so schnell wie möglich kontaktieren</p>
                        <input id='feedback-name' type="text" placeholder="Ihre Name" className="feedback__name" />
                        <input id='feedback-mail' type="text" placeholder="E-Mail" className="feedback__email" />
                        <textarea id='feedback-text' className="feedback__message" placeholder="Ihre Nachricht" />
                        <div className="feedback__checkbox">
                            <div  className="feedback__box">
                                <input type="checkbox" id="feedback__checkbox" />
                                <label  htmlFor="feedback__checkbox" onClick={this.onClickCheckBox}  />
                            </div>
                            <div className="feedback__label">
                                <p className="feedback__checkbox-text">
                                    Ich stimme der Verarbeitung meiner
                                </p>
                                <a className="feedback__link" href="/datenschutz" >
                                    persönlichen Daten zu 
                                </a>                                    
                            </div>
                        </div>  
                        <button onClick={this.onClickSendMessage} className={`feedback__button button ${disabled}`}>Abschicken</button>
                    </div>
                </div>
                </section>

                <MessageSent hasSent={this.state.hasSent} onClose={this.onClickCloseMessage} />
            </section>
        );
    }

    private onClickCloseMessage () {
        this.setState({hasSent: false});
    }

    private onClickCheckBox() {
        this.setState({
            isChecked: !this.state.isChecked
        });
    }
    private onClickSendMessage(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

        const name = (document.getElementById('feedback-name') as HTMLInputElement);
        const email = (document.getElementById('feedback-mail') as HTMLInputElement);
        const text = (document.getElementById('feedback-text') as HTMLTextAreaElement);

        if (this.state.isChecked && (name.value !== '' && email.value !== '' && text.value !== '')) {
            const message = {
                name: name.value,
                email: email.value,
                text: text.value,
                //phone: phone.value
            }
            const data = {
                message: message,
                subject: 'Wunsch | Desire'
            }
        

            
            fetch('https://enconso-api.vistar.su/messages/', { method: 'POST', headers:{'Content-Type':'application/json'}, body: JSON.stringify(data) });
            

            name.value = '';
            email.value = '';
            text.value = '';
            
           this.setState({hasSent: true});
           this.onClickCheckBox();
           (document.getElementById('feedback__checkbox') as HTMLInputElement).checked = false;
        }
    }
}