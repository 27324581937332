import * as React from "react";
import './SmallInfoBlocks.scss';

export interface IBlock {
    title: string;
    info: string;
    link?: string;
    class: string;
}

export interface ISSmallInfoBlocksProps {
    blocks: IBlock[];
}

export default class SmallInfoBlocks extends React.Component<ISSmallInfoBlocksProps> {
    public render() {
        return (
            <div className={`small-info-block__section `}>
            {this.props.blocks.map((item, i) => {
                return (
                    <div 
                        key={i} 
                        className={`small-info-block ${i % 2 === 0 ? 'left' : 'right'}`} 
                        style={{ backgroundImage: `url('/img/small-info-block/${item.class}.jpg')` }}>
                        <div className="small-info-block__container">
                            <h1 className="small-info-block__title">{item.title}</h1>
                            <p className={`small-info-block__info`}>{item.info}</p>
                            {item.link ? <a className="small-info-block__button button" href={item.link}>Mehr Info</a> : ''}
                        </div>
                    </div>
                );
            })}
            </div>
        );
    }
}