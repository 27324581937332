import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";
import './ArrowTo.scss'

export default class ArrowTo extends React.Component {
    public render(){
        return(
            <Link smooth={true} to='#startAnchor' className="arrow" />
            
        );
    }
}