import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";
import './NonSAPSolutions.scss';
const columns = [
    [
        (<>Front-/Back-end <br/>Development</>),
        `Java`,
        `JavaScript`,
        `C++`,
        `C#`,
        `PHP`,
        ``
    ],
    [
        <>Database<br/> Development</>,
        `MySQL`,
        `Oracle`,
        `MongoDB`,
        `ElasticSearch`,
        `Neo4j`,
        ``
    ],
    [
        <>Web/Mobile <br/>Development</>,
        `HTML`,
        `SASS`,
        `Bootstrap`,
        `iOS`,
        `Android`,
        ``
    ],
    [
        <>Project<br/>Management Tools</>,
        `Apache`,
        `Maven`,
        `Gradle`,
        `Jenkins CI`,
        `JIRA`,
        `Redmine`,
    ]
];


export default class NonSAPSolutions extends React.Component{
    public render() {
        return (
            <section className="non-sap-solutions section">
                <div className="non-sap-solutions__container container">
                <h3 className="non-sap-solutions__title white">NonSAP-Solutions</h3>
                <div className="table">
                        {
                            this.renderTable()
                        }
                </div>
                <Link to='non-sap-solutions#topAnchor'><button  className="button">Mehr Info</button></Link>
                </div>
            </section>           
        );
    }
    private renderTable() {
        const mas = new Array(columns[0].length);

        columns.forEach((row, i) => {
            mas[i] = [];
            columns[0].forEach((col, j) => {
                mas[i].push(<div className="td" key={i+j}>{columns[i][j]}</div>);
            })
        })

        return mas.map( (item, i) => <div className="tr" key={i}>{item}</div>);
    }
}